import React, { useRef, useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import referrallogo from "../../../../assets/icons/Referrals.png";
import BankNavbar from "./BankNavbar";
import discordlogo from "../../../../assets/WEBSITE/LOGO_DISCORD.png";
import questbg from "../../../../assets/icons/Quest.png";
import Tooltip from "@mui/material/Tooltip";
import activitybg from "../../../../assets/icons/ACtivity.png";
import CircularProgress from "@mui/material/CircularProgress";
import connectbg from "../../../../assets/icons/Connect.png";
import twitterlogo from "../../../../assets/WEBSITE/LOGO_X.png";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import "./bank.css";
import gsap from "gsap";
import zero from "../../../../assets/NUMBERS/B0.png";
import one from "../../../../assets/NUMBERS/B1.png";
import two from "../../../../assets/NUMBERS/B2.png";
import three from "../../../../assets/NUMBERS/B3.png";
import four from "../../../../assets/NUMBERS/B4.png";
import five from "../../../../assets/NUMBERS/B5 (1).png";
import six from "../../../../assets/NUMBERS/B6.png";
import seven from "../../../../assets/NUMBERS/B7.png";
import eight from "../../../../assets/NUMBERS/B8.png";
import nine from "../../../../assets/NUMBERS/B9.png";
import leaderboardbg from "../../../../assets/icons/Leaderboard.png";
import leaderboardstarbg from "../../../../assets/images/star.jpg";
import bankview from "../../../../assets/images/casinowithoutcards.jfif";
import questbox from "../../../../assets/images/questbox.png";
import questalone from "../../../../assets/images/questalone.png";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import whitelistmob from "../../../../assets/images/whitelistmob.jpg";
import factionsfull from "../../../../assets/icons/FACTIONS.png";
import questsbgfull from "../../../../assets/icons/QUESTS.png";
import axios from "axios";
import { CustomAlert, useAlert } from "../../../shared/CustomAlert";
import { nanoid } from "@reduxjs/toolkit";
import { Link, useNavigate } from "react-router-dom";
import BankQuestsCardAlone from "./BankQuestsCardAlone";

function BankWhitelistView({
    data,

    pageName,
    setisElementsHidden,

    isElementsHidden,
    handleHide,
    setPageName,
    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    isLargeScreen,
    setIsLargeScreen,
    audioRef,
    playMusic,
}) {
    const containerRef = useRef(null);
    const [newspaper, setnewspaper] = useState(false);
    const [posters, setposters] = useState(false);
    const hasTwitter = localStorage.getItem("twitter") !== null;
    const hasDiscord = localStorage.getItem("discord") !== null;
    const [suits, setsuits] = useState(false);
    const [isquests, setisquests] = useState(false);
    const [isreferral, setisreferral] = useState(false);
    const [referralData, setreferralData] = useState("");
    const [userAnswer11, setuserAnswer11] = useState("");
    const [userAnswer12, setuserAnswer12] = useState("");
    const [userAnswer15, setuserAnswer15] = useState("");
    const [userAnswer17, setuserAnswer17] = useState("");
    const [userAnswer19, setUserAnswer19] = useState("");
      const [userAnswer21, setUserAnswer21] = useState("");
    const [referralvalue, setreferralvalue] = useState(null);
    const [isquestsbox, setisquestsbox] = useState(false);
    const [isEasterEggPointsGiven, setisEasterEggPointsGiven] = useState(false);
    const [isEasterEggQuest19PointsGiven, setisEasterEggQuest19PointsGiven] =
        useState(false);
            const [isEasterEggQuest21PointsGiven, setisEasterEggQuest21PointsGiven] =
        useState(false);
                const [isEasterEggQuest21Box, setisEasterEggQuest21Box] = useState(false);
    const [isEasterEggQuest19Box, setisEasterEggQuest19Box] = useState(false);
    const [isEasterEggAddressBox, setisEasterEggAddressBox] = useState(false);


    const [isEasterEggLocationBox, setisEasterEggLocationBox] = useState(false);
    const [isEasterEggLocationPointsGiven, setisEasterEggLocationPointsGiven] =
        useState(false);
    const [isEasterEggAddressPointsGiven, setisEasterEggAddressPointsGiven] =
        useState(false);
    const [isEasterEggPostageBox, setisEasterEggPostageBox] = useState(false);
    const [isEasterEggPostagePointsGiven, setisEasterEggPostagePointsGiven] =
        useState(false);
    const [isEasterEggBox, setisEasterEggBox] = useState(false);
    const [isMultiplequests, setisMultiplequests] = useState(false);
    const [scoreObjectData, setscoreObjectData] = useState(null);
    const [isactivity, setisactivity] = useState(false);
    const [isbankcasinoviewanimating, setisbankcasinoviewanimating] =
        useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (newspaper || posters || suits) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [newspaper, posters, suits, isactivity, isquests]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const platform = urlParams.get("platform");
        const uid = urlParams.get("uid");
        const referral = urlParams.get("referral");
        if (referral) {
            localStorage.setItem("referral", referral);
            localStorage.setItem("refferedBy", referral);
        }
        if (platform && uid) {
            localStorage.setItem(platform, uid);
            window.location.href = "/whitelist";
        }
    }, []);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);

            audioRef.current.volume = 0.35;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.2;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bankwhitelistview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        if (isAnimating === true) {
            gsap.to("#bankwhitelistview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    useEffect(() => {
        if (isbankcasinoviewanimating === true) {
            gsap.to("#bankwhitelistview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/casino");
                },
            });
        }

        return () => {};
    }, [isbankcasinoviewanimating]);
    useEffect(() => {
        setisbankcasinoviewanimating(false);
    }, [isbankcasinoviewanimating]);

    const { alert, showAlert, hideAlert } = useAlert();

    const handleConnectDiscord = async () => {
        try {
            const twitterId = localStorage.getItem("twitter");
            const discordId = localStorage.getItem("discord");
            const refferedBy = localStorage.getItem("referral");
            if (discordId) {
                showAlert(
                    "You have already connected your discord account",
                    "Alert"
                );
                return;
            }
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/connectUrl/discord/${twitterId}`,
                {
                    params: {
                        refferedBy,
                        myReferralCode: myReferralCode || nanoid(6),
                    },
                }
            );
            // replace the window.open with a redirect to the url
            window.open(res?.data?.url, "_self");
        } catch (error) {
            console.error(error);
        }
    };
    console.log("isEasterEggQuest19Box", isEasterEggQuest19Box);
    const handleConnectTwitter = async () => {
        try {
            const twitter = localStorage.getItem("twitter");
            const discord = localStorage.getItem("discord");
            const refferedBy = localStorage.getItem("refferedBy");
            if (twitter) {
                showAlert(
                    "You have already connected your twitter account",
                    "Alert"
                );
                return;
            }
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/connectUrl/twitter/${discord}`,
                {
                    params: {
                        refferedBy,
                        myRefferalCode: myReferralCode || nanoid(6),
                    },
                }
            );
            window.open(res?.data?.url, "_self");
        } catch (error) {
            console.error(error);
        }
    };

    const [activityLogs, setActivityLogs] = useState([]);
    const [scoreArray, setScoreArray] = useState([]);

    const [questsArray, setQuestsArray] = useState([
        {
            id: 1,
            name: "You want a seat at the table? ",
            name2: "Engage with The Mob’s latest tweets.",
            isDone: false,
            points: 5,
        },
        {
            id: 2,
            name: "Prove your loyalty. ",
            name2: "Follow: @themobonbtc",
            isDone: false,
            scoreTag: "followingCompany",
            link: "https://twitter.com/intent/follow?screen_name=themobonbtc",
            points: 5,
        },
        {
            id: 3,
            name: "Join us. ",
            name2: "discord.gg/themobonbtc",
            isDone: false,
            link: "https://discord.gg/themobonbtc",
            scoreTag: "joinedDiscord",
            points: 3,
        },

        {
            id: 4,
            name: "What's a family without loyalty?",
            name2: " Pass the Discord waiting room and join us, Citizen.",
            isDone: false,
            points: 15,
        },
        {
            id: 5,
            name: "The Inner Circle is watching you.",
            name2: "Follow: @NymosXBT",
            isDone: false,
            scoreTag: "followingNymos",
            link: "https://twitter.com/intent/follow?screen_name=NymosXBT",
            points: 5,
        },
        {
            id: 6,
            name: "What's a family without blood?",
            name2: "Tag 3 friends on the Mob's tweets!", // comment
            isDone: false,
            points: 3,
        },
        {
            id: 7,
            name: "Are you one of us ?",
            name2: "Tweet: `Lurking the night, never in sight` and Tag @themobonbtc at the end",
            link: "https://twitter.com/intent/tweet?text=Lurking%20the%20night%2C%20never%20in%20sight&via=themobonbtc",
            isDone: false,
            points: 10,
        },
        {
            id: 8,
            name: "The Mob is recruiting",
            name2: "Refer us to your friends!",
            isDone: false,
            points: 10,
        },
        {
            id: 9,
            name: "We need your help",
            name2: "Join a faction",
            isDone: false,
            points: 10,
        },
        {
            id: 10,
            name: "Are you one of us ?",
            name2: "Quote RT your faction",
            isDone: false,
            link: "https://twitter.com/themobonbtc/status/1788260096495198381",
            points: 3,
        },
        {
            id: 11,
            name: "There's a rat amongst us.",
            name2: "Find the snitch and tell us who he is.",
            scoreTag: "easterEgg",
            isDone: false,
            points: 10,
        },
        {
            id: 12,
            name: "The Runners are trying to tell us something.",
            name2: " Decipher the code!",
            scoreTag: "easterEgg_address",
            isDone: false,
            points: 10,
        },
        {
            id: 13,
            name: "More games.",
            name2: "QT The Mob's Runners Quest with your favorite puzzle.",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1793280806951297506",
            points: 5,
        },
        {
            id: 14,
            name: "The casino contains a variety of fun.",
            name2: "QT your favorite game.",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1792556125294096720",
            points: 5,
        },
        {
            id: 15,
            name: "In the back alleys of the city, where shadows whisper secrets, I stand four men deep.",
            name2: " I travel the world but never leave my corner. What am I?",
            scoreTag: "easterEgg_postage",
            isDone: false,
            points: 10,
        },
        {
            id: 16,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1795833148716315054",
            points: 5,
        },

        {
            id: 17,
            name: "Find The Boss.",
            name2: "Tell us his location.",
            scoreTag: "easterEgg_quest17",
            isDone: false,
            points: 10,
        },
        {
            id: 18,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1796557306806849638",
            points: 5,
        },
        {
            id: 19,
            name: "I speak without a mouth and hear without ears.",
            name2: " I have no body, but I come alive with the wind. What am I?",
            scoreTag: "easterEgg_quest19",
            isDone: false,
            points: 5,
        },
        {
            id: 20,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1798007730353131912",
            points: 5,
        },
        {
            id: 21,
            name: "A quiet whisper in the back alleys of the city, I'm seen when things go dark but never in the light..",
            name2: "Cross me, and you'll pay the price. What am I?",
            scoreTag: "easterEgg_quest21",
            isDone: false,
            points: 5,
        },
        {
            id: 22,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1798731854658547925?s=46&t=827hdAbvWk8m1PHa-0QIQg",
            points: 5,
        },
                {
            id: 23,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1800181226575474849",
            points: 5,
        },
   {
            id: 24,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1800913499830526299",
            points: 5,
        },
   {
            id: 25,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1802718117363388428",
            points: 5,
        },
           {
            id: 26,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1803442818544975872?s=46",
            points: 5,
        },
           {
            id: 27,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1804167659657060529",
            points: 5,
        },
         {
            id: 28,
            name: "Interact with The Mob's post.",
            // name2: "Quote RT your faction",
            isDone: false,
            link: "https://x.com/themobonbtc/status/1805254894938652705",
            points: 5,
        },
        // {
        //     id: 13,
        //     name: "Riddle 1",
        //     name2: "In the back alleys of the city, where shadows whisper secrets, I stand four men deep. I travel the world but never leave my corner. What am I?",
        //     scoreTag: "easterEgg_postage",
        //     isDone: false,
        //     points: 10,
        // },

        // {
        //     id: 1,
        //     name: "Connect Twitter",
        //     isDone: false,
        //     points: 10,
        // },

        // {
        //     id: 3,
        //     name: "Retweet our Posts (x15)",
        //     isDone: false,
        //     points: 10,
        // },

        // {
        //     id: 8,
        //     name: "Follow Fools on X",
        //     isDone: false,
        //     scoreTag: "followingFools",
        //     link: "https://twitter.com/intent/follow?screen_name=",
        //     points: 10,
        // },
        // {
        //     id: 9,
        //     name: "Refer a friend to join TheMob",
        //     isDone: false,
        //     link: "referral",
        //     scoreTag: "referral",
        //     points: 10,
        // },
    ]);

    const handleTwitterFollow = async (url, scoreTag) => {
        try {
            const twitterUid = localStorage.getItem("twitter");
            if (!twitterUid) {
                showAlert("Please connect your twitter account first", "Alert");
                return;
            }
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/updateScore/${twitterUid}/${scoreTag}`
            );
            window.open(url, "_blank");
        } catch (error) {
            console.error(error);
        }
    };

    const [isUserDataLoading, setisUserDataLoading] = useState(false);

    const [isLeaderBoardOpen, setisLeaderBoardOpen] = useState(false);

    const [leaderBoardData, setLeaderBoardData] = useState([]);
    const [isLeaderBoardLoading, setisLeaderBoardLoading] = useState(false);

    const handleFetchLeaderBoard = async () => {
        try {
            setisLeaderBoardOpen(true);
            setisLeaderBoardLoading(true);
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/getLeaderBoard`
            );
            setLeaderBoardData(res?.data);
        } catch (error) {
            console.error(error);
        } finally {
            setisLeaderBoardLoading(false);
        }
    };

    function getOrdinalSuffix(i) {
        const j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    useEffect(() => {}, [isEasterEggPointsGiven]);
    //ada
    const [currentUserName, setCurrentUserName] = useState(null);

    const [currentUserScore, setCurrentUserScore] = useState(null);
    const handleFetchUserData = async () => {
        try {
            setisUserDataLoading(true);
            const twitterUid = localStorage.getItem("twitter");
            if (!twitterUid) {
                setScoreArray([zero, zero, zero]);
                return;
            }
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/getData/${twitterUid}`
            );
            setisEasterEggPointsGiven(
                res?.data?.scoreObject?.easterEgg !== null &&
                    res?.data?.scoreObject?.easterEgg !== undefined
            );
            setisEasterEggQuest19PointsGiven(
                res?.data?.scoreObject?.easterEgg_quest19 !== null &&
                    res?.data?.scoreObject?.easterEgg_quest19 !== undefined
            );
                      setisEasterEggQuest21PointsGiven(
                res?.data?.scoreObject?.easterEgg_quest21 !== null &&
                    res?.data?.scoreObject?.easterEgg_quest21 !== undefined
            );
            setisEasterEggLocationPointsGiven(
                res?.data?.scoreObject?.easterEgg_quest17 !== null &&
                    res?.data?.scoreObject?.easterEgg_quest17 !== undefined
            );
            setisEasterEggAddressPointsGiven(
                res?.data?.scoreObject?.easterEgg_address !== null &&
                    res?.data?.scoreObject?.easterEgg_address !== undefined
            );
            setisEasterEggPostagePointsGiven(
                res?.data?.scoreObject?.easterEgg_postage !== null &&
                    res?.data?.scoreObject?.easterEgg_postage !== undefined
            );
            setreferralvalue(res?.data?.refferedBy);
            setCurrentUserName(
                res?.data?.twitterUser?.data?.username ||
                    res?.data?.discordUserDetails?.username
            );
            setMyReferralCode(res?.data?.myRefferalCode);
            setActivityLogs(res?.data?.activityLogs);

            const totalScore = Object.values(
                res?.data?.scoreObject || {}
            ).reduce((acc, value) => acc + value, 0);
            setscoreObjectData({ ...res?.data?.scoreObject, totalScore });

            const scoreImageArrary = [];
            const theCalcScore = Object.keys(res?.data?.scoreObject)
                ?.reduce((acc, key) => {
                    return acc + res?.data?.scoreObject[key];
                }, 0)
                ?.toString();
            setCurrentUserScore(theCalcScore);
            // convert the score to have 3 digits, (pad with zeros)
            if (twitterUid) {
                setQuestsArray((prev) =>
                    prev.map((x) => {
                        if (x.id === 1) {
                            return { ...x, isDone: true };
                        }
                        return x;
                    })
                );
            }
            Object.keys(res?.data?.scoreObject).forEach((key) => {
                const isTweetQuestDone =
                    res?.data?.scoreObject["retweetedCompanyTweets"] >= 45 &&
                    res?.data?.scoreObject["likedCompanyTweets"] >= 15 &&
                    res?.data?.scoreObject["commentedCompanyTweets"] >= 30;

                if (res?.data?.scoreObject?.["easterEgg"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 11) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }

                if (isTweetQuestDone) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 1) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject["followingCompany"] === 5) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 2) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.isFoundInServer) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 3) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject["citizens"] === 15) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 4) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject["followingNymos"] === 5) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 5) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["commentedCompanyTweets"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 6) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.checks?.["quest7"] === true) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 7) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject["referral"] >= 10) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 8) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.faction?.factionRoleId) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 9) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.checks?.["quest10"] === true) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 10) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.checks?.["quest13"] === true) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 13) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.checks?.["quest14"] === true) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 14) {
                                return {
                                    ...x,
                                    isDone: true,
                                };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["easterEgg"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 11) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["easterEgg_postage"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 15) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["easterEgg_address"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 12) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["easterEgg_quest17"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 17) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (res?.data?.scoreObject?.["easterEgg_quest19"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 19) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                                if (res?.data?.scoreObject?.["easterEgg_quest21"] > 0) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 21) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (
                    res?.data?.checks?.commentCheckedTweets?.includes(
                        "1795833148716315054"
                    ) &&
                    res?.data?.checks?.retweetCheckedTweets?.includes(
                        "1795833148716315054"
                    ) &&
                    res?.data?.checks?.likeCheckedTweets?.includes(
                        "1795833148716315054"
                    )
                ) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 16) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                if (
                    res?.data?.checks?.commentCheckedTweets?.includes(
                        "1796557306806849638"
                    ) &&
                    res?.data?.checks?.retweetCheckedTweets?.includes(
                        "1796557306806849638"
                    ) &&
                    res?.data?.checks?.likeCheckedTweets?.includes(
                        "1796557306806849638" //TODO: ADD NEW TWEET ID
                    )
                ) {
                    setQuestsArray((prev) =>
                        prev.map((x) => {
                            if (x.id === 18) {
                                return { ...x, isDone: true };
                            }
                            return x;
                        })
                    );
                }
                  if (
                      res?.data?.checks?.commentCheckedTweets?.includes(
                          "1798007730353131912"
                      ) &&
                      res?.data?.checks?.retweetCheckedTweets?.includes(
                          "1798007730353131912"
                      ) &&
                      res?.data?.checks?.likeCheckedTweets?.includes(
                          "1798007730353131912" //TODO: ADD NEW TWEET ID
                      )
                  ) {
                      setQuestsArray((prev) =>
                          prev.map((x) => {
                              if (x.id === 20) {
                                  return { ...x, isDone: true };
                              }
                              return x;
                          })
                      );
                  }
                                    if (
                      res?.data?.checks?.commentCheckedTweets?.includes(
                          "1798731854658547925"
                      ) &&
                      res?.data?.checks?.retweetCheckedTweets?.includes(
                          "1798731854658547925"
                      ) &&
                      res?.data?.checks?.likeCheckedTweets?.includes(
                          "1798731854658547925" //TODO: ADD NEW TWEET ID
                      )
                  ) {
                      setQuestsArray((prev) =>
                          prev.map((x) => {
                              if (x.id === 22) {
                                  return { ...x, isDone: true };
                              }
                              return x;
                          })
                      );
                  }
                        if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1800181226575474849"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1800181226575474849"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1800181226575474849" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 23) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                          if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1800913499830526299"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1800913499830526299"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1800913499830526299" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 24) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                                          if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1802718117363388428"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1802718117363388428"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1802718117363388428" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 25) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                                 if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1803442818544975872"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1803442818544975872"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1803442818544975872" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 26) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                          if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1804167659657060529"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1804167659657060529"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1804167659657060529" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 27) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                            if (
                            res?.data?.checks?.commentCheckedTweets?.includes(
                                "1805254894938652705"
                            ) &&
                            res?.data?.checks?.retweetCheckedTweets?.includes(
                                "1805254894938652705"
                            ) &&
                            res?.data?.checks?.likeCheckedTweets?.includes(
                                "1805254894938652705" //TODO: ADD NEW TWEET ID
                            )
                        ) {
                            setQuestsArray((prev) =>
                                prev.map((x) => {
                                    if (x.id === 28) {
                                        return { ...x, isDone: true };
                                    }
                                    return x;
                                })
                            );
                        }
                // if (res?.data?.scoreObject["followingFools"] === 7.5) {
                //     setQuestsArray((prev) =>
                //         prev.map((x) => {
                //             if (x.id === 8) {
                //                 return { ...x, isDone: true };
                //             }
                //             return x;
                //         })
                //     );
                // }
            });
            theCalcScore
                ?.padStart(3, "0")
                ?.split("")
                ?.forEach((x) => {
                    switch (x) {
                        case "0":
                            scoreImageArrary.push(zero);
                            break;
                        case "1":
                            scoreImageArrary.push(one);
                            break;
                        case "2":
                            scoreImageArrary.push(two);
                            break;
                        case "3":
                            scoreImageArrary.push(three);
                            break;
                        case "4":
                            scoreImageArrary.push(four);
                            break;
                        case "5":
                            scoreImageArrary.push(five);
                            break;
                        case "6":
                            scoreImageArrary.push(six);
                            break;
                        case "7":
                            scoreImageArrary.push(seven);
                            break;
                        case "8":
                            scoreImageArrary.push(eight);
                            break;
                        case "9":
                            scoreImageArrary.push(nine);
                            break;
                        default:
                            break;
                    }
                });
            if (totalScore <= 0) {
                setScoreArray([zero, zero, zero]);
                return;
            }
            setScoreArray(scoreImageArrary);
        } catch (error) {
            console.error(error);
        } finally {
            setisUserDataLoading(false);
        }
    };
    const handleDisconnectTwitter = () => {
        localStorage.removeItem("twitter");
        window.location.reload();
    };
    const handleDisconnectDiscord = () => {
        localStorage.removeItem("discord");
        window.location.reload();
    };
    const [isQuestsLoading, setisQuestsLoading] = useState(false);

    const [myReferralCode, setMyReferralCode] = useState(null);
    useEffect(() => {
        if (referralvalue !== null) {
            // Logic to close the input
        }
    }, [referralvalue]);
    const handleFetchQuests = useCallback(async () => {
        try {
            setisQuestsLoading(true);
            const twitterUid = localStorage.getItem("twitter");
            if (!twitterUid) {
                return;
            }
            const res = await axios.get(
                `https://themobserver.themobonbtc.com/refreshScores/${twitterUid}`
            );
        } catch (error) {
            console.error(error);
        } finally {
            setisQuestsLoading(false);
        }
    }, []);

    const updateRefferal = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/setReferredBy",
                {
                    twitterId: localStorage.getItem("twitter"),
                    referredBy: referralData,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage = response.data;
                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const updateQuest11 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest11",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer11,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage =
                    response.data === "Easter Egg Found, 10 points rewarded"
                        ? "You found the snitch"
                        : response.data;
                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const updateQuest12 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest12",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer12,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage =
                    response.data === "Easter Egg Found, 10 points rewarded"
                        ? "You found the address"
                        : response.data;
                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const updateQuest15 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest15",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer15,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage =
                    response.data === "Easter Egg Found, 10 points rewarded"
                        ? "You found the answer"
                        : response.data;
                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const updateQuest17 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest17",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer17,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage =
                    response.data === " points rewarded"
                        ? "You found the answer"
                        : response.data;
                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const updateQuest19 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest19",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer19,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage = "You found the answer";

                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

      const updateQuest21 = async () => {
        try {
            const response = await axios.post(
                "https://themobserver.themobonbtc.com/quest21",
                {
                    twitterId: localStorage.getItem("twitter"),
                    userAnswer: userAnswer21,
                }
            );

            // Check the status code of the response
            if (response.status === 200) {
                const successMessage = "You found the answer";

                // Request was successful, display success message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                // Request failed, display error message
                console.error("Request failed:", response.data);
                toast.error("Failed to add referral link", {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response.data, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    const copyReferralLink = () => {
        const referralLink = `${myReferralCode}`;
        navigator.clipboard
            .writeText(referralLink)
            .then(() => {})
            .catch((error) => {
                console.error(
                    "Failed to copy referral link to clipboard:",
                    error
                );
            });
    };

    useEffect(() => {
        handleFetchUserData();
    }, []);

    useEffect(() => {
        handleFetchQuests();
    }, [handleFetchQuests]);

    useEffect(() => {
        const interval = setInterval(() => {
            handleFetchQuests();
        }, 300000);
        return () => clearInterval(interval);
    }, [handleFetchQuests]);

    const sortedData = leaderBoardData.sort(
        (a, b) => b.totalScore - a.totalScore
    );

    const handleOpenReferral = () => {
        const twitterUid = localStorage.getItem("twitter");
        if (
            !twitterUid ||
            currentUserName === undefined ||
            currentUserName === null
        ) {
            showAlert("Please connect your twitter account first", "Alert");
            return;
        }
        setisreferral(true);
    };

    const isUserInLeaderboards =
        currentUserName &&
        leaderBoardData.find(
            (y) =>
                y?.twitterName.toLowerCase() === currentUserName?.toLowerCase()
        );

    return (
        <div
            ref={containerRef}
            className="relative z-10 min-h-screen max-h-screen flex flex-col items-center mx-auto overflow-auto w-full  "
            id="bankwhitelistview"
        >
            <div className="relative max-h-screen">
                {isLargeScreen ? (
                    <img
                        src={bankview}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={whitelistmob}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}
                {/* connect and social div-------------------------------------------------------------------------------- */}
                <div
                    id="socialsDiv"
                    className="absolute top-[32%] left-[10%] xl:top-[26%] xl:left-[14%] sm:left-[15%] md:left-[14%] md:top-[32%] flex-col flex gap-1 md:gap-0 lg:gap-5 align-middle items-center justify-center "
                >
                    <div className=" mb-2 lg:mb-5">
                        <img
                            src={connectbg}
                            className="w-[60px] md:w-[100px] lg:w-[120px]   xl:w-[180px] 2xl:w-[200px]"
                            alt=""
                        />
                    </div>
                    <div className="flex gap-3 sm:gap-3 md:gap-4 lg:gap-10 align-middle items-center justify-center">
                        <Tooltip
                            title={` ${
                                hasTwitter
                                    ? "Disconnect Twitter"
                                    : "Connect Twitter"
                            }`}
                        >
                            <img
                                src={twitterlogo}
                                onClick={() =>
                                    hasTwitter
                                        ? handleDisconnectTwitter()
                                        : handleConnectTwitter()
                                }
                                className="2xl:w-[50px]  lg:w-[40px] md:w-[30px] w-[10px] cursor-pointer hover:scale-105 duration-150  "
                                alt=""
                            />
                        </Tooltip>
                        {/* <img //! Removing until wallet is implemented
              src={walletlogo}
              className="w-[70px] cursor-pointer  hover:scale-105 duration-150 "
              alt=""
            /> */}
                        <Tooltip
                            title={` ${
                                hasDiscord
                                    ? "Disconnect Discord"
                                    : "Connect Discord"
                            }`}
                        >
                            {" "}
                            <img
                                src={discordlogo}
                                onClick={() =>
                                    hasDiscord
                                        ? handleDisconnectDiscord()
                                        : handleConnectDiscord()
                                }
                                className="2xl:w-[65px] lg:w-[45px] md:w-[35px] w-[14px]  cursor-pointer hover:scale-105 duration-150  "
                                alt=""
                            />{" "}
                        </Tooltip>
                    </div>
                </div>
                {/* quests div-------------------------------------------------------------------------------- */}
                <div
                    id="questsDiv"
                    className=" rotate-45 sm:rotate-[30deg] md:rotate-[25deg] lg:rotate-[40deg] xl:rotate-[20deg] absolute top-[45%] left-[25%] sm:left-[29%] md:top-[45%] md:left-[27%] lg:left-[26%] lg:top-[57%] xl:top-[58%] xl:left-[27%] 2xl:top-[60%] 2xl:left-[28%] flex-col flex gap-4 md:gap-10 justify-center items-center align-middle"
                >
                    <div className="flex flex-col gap-7 md:gap-7 xl:gap-10 2xl:gap-10">
                        <img
                            src={questsbgfull}
                            onClick={() => {
                                setisquests(true);
                            }}
                            className="w-[40px] sm:w-[50px] md:w-[80px] lg:w-[100px] 2xl:w-[140px] flex justify-center mx-auto cursor-pointer  hover:scale-105 duration-200 ease-in "
                            alt=""
                        />
                    </div>
                    {isquests && (
                        <Modal
                            className=" w-screen min-h-screen border-none h-full  mx-auto outline-none justify-center   "
                            open={isquests === true}
                            onClose={() => {
                                setisquests(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className="outline-none h-full  items-center justify-center align-middle flex ">
                                <div
                                    className="absolute right-5 top-10 2xl:top-5 2xl:right-10 z-40 cursor-pointer  "
                                    onClick={() => setisquests(false)}
                                >
                                    <i className="fa-solid fa-xmark text-white text-[20px] 2xl:text-[40px] z-40"></i>
                                </div>
                                <div className="flex items-center   flex-col lg:flex-row lg:flex-wrap justify-normal pb-28 lg:pb-2  lg:justify-center py-10 mx-auto outline-none h-full w-screen lg:py-10  lg:h-full   overflow-auto lg:gap-4   lg:pt-2 px-10  gap-10   ">
                                    {!isQuestsLoading &&
                                        questsArray.map((y) => (
                                            <div
                                                className={` 2xl:w-[25%] sm:w-[70%] md:w-[70%] lg:w-[30%] w-[100%]   flex ${
                                                    y?.isDone
                                                        ? "hover:brightness-100"
                                                        : "hover:brightness-125"
                                                }  duration-200 ease-in   z-20  `}
                                            >
                                                <BankQuestsCardAlone
                                                    y={y}
                                                    isEasterEggPointsGiven={
                                                        isEasterEggPointsGiven
                                                    }
                                                    isEasterEggAddressPointsGiven={
                                                        isEasterEggAddressPointsGiven
                                                    }
                                                    setisEasterEggQuest19Box={
                                                        setisEasterEggQuest19Box
                                                    }
                                                    isEasterEggQuest19Box={
                                                        isEasterEggQuest19Box
                                                    }
                                                    isEasterEggQuest19PointsGiven={
                                                        isEasterEggQuest19PointsGiven
                                                    }

 setisEasterEggQuest21Box={
                                                        setisEasterEggQuest21Box
                                                    }
                                                    isEasterEggQuest21Box={
                                                        isEasterEggQuest21Box
                                                    }
                                                    isEasterEggQuest21PointsGiven={
                                                        isEasterEggQuest21PointsGiven
                                                    }

                                                    isEasterEggLocationPointsGiven={
                                                        isEasterEggLocationPointsGiven
                                                    }
                                                    setisEasterEggLocationBox={
                                                        setisEasterEggLocationBox
                                                    }
                                                    setisEasterEggAddressBox={
                                                        setisEasterEggAddressBox
                                                    }
                                                    isEasterEggPostageBox={
                                                        isEasterEggPostageBox
                                                    }
                                                    isEasterEggLocationBox={
                                                        isEasterEggLocationBox
                                                    }
                                                    isEasterEggPostagePointsGiven={
                                                        isEasterEggPostagePointsGiven
                                                    }
                                                    setisEasterEggPostageBox={
                                                        setisEasterEggPostageBox
                                                    }
                                                    updateRefferal={
                                                        updateRefferal
                                                    }
                                                    setreferralData={
                                                        setreferralData
                                                    }
                                                    referralData={referralData}
                                                    setisEasterEggBox={
                                                        setisEasterEggBox
                                                    }
                                                    isEasterEggBox={
                                                        isEasterEggBox
                                                    }
                                                    setisquests={setisquests}
                                                    showAlert={showAlert}
                                                    handleTwitterFollow={
                                                        handleTwitterFollow
                                                    }
                                                    myReferralCode={
                                                        myReferralCode
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
                {/* factions div-------------------------------------------------------------------------------- */}
                <div
                    id="factionsDiv"
                    className="absolute  rotate-[-45deg] sm:rotate-[-30deg] md:rotate-[-25deg] lg:rotate-[-40deg] xl:rotate-[-30deg] top-[45%] right-[25%] sm:right-[27%] sm:top-[45%] md:top-[44%] md:right-[26%] lg:right-[26%] lg:top-[56%] xl:top-[55%] xl:right-[27%] 2xl:top-[56%] 2xl:right-[27%] flex-col flex gap-4 md:gap-10 justify-center items-center align-middle"
                >
                    <div className=" ">
                        <div className="flex flex-col gap-7 md:gap-7 xl:gap-10 2xl:gap-10">
                            <Link to="/slot">
                                <img
                                    src={factionsfull}
                                    className="w-[50px] md:w-[90px] lg:w-[120px] 2xl:w-[180px] flex justify-center mx-auto cursor-pointer  hover:scale-105 duration-200 ease-in "
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                {/* 
                {myReferralCode && (
                    <div className="absolute top-[16%] right-[10%]  flex-col flex gap-5 align-middle items-center justify-center ">
                        <div className="mb-5">
                            <img
                                src={connectbg}
                                className="w-[424px] "
                                alt=""
                            />
                        </div>
                        <div className="flex gap-10 align-middle items-center justify-center">
                            <p className="text-white text-2xl cursiveHandwriting">
                                {`${window.location.origin}/?referral=${myReferralCode}`}
                            </p>
                        </div>
                    </div>
                )} */}

                {/* quest  div-------------------------------------------------------------------------------- */}
                <div
                    id="questDiv"
                    className="absolute top-[32%] left-[69%] xl:top-[26%] xl:left-[73%] 2xl:top-[26%] 2xl:left-[72%] outline-none sm:left-[75%] md:left-[72%] md:top-[32%] lg:left-[72%] lg:top-[32%] flex-col flex gap-2 md:gap-0 lg:gap-5 align-middle items-center justify-center "
                >
                    <div className="mb-5">
                        <img
                            onClick={handleOpenReferral}
                            src={referrallogo}
                            className="w-[65px] sm:w-[60px] md:w-[100px] lg:w-[120px]   xl:w-[180px] 2xl:w-[210px] cursor-pointer"
                            alt=""
                        />
                    </div>
                </div>
                {/* quest 11 div-------------------------------------------------------------------------------- */}
                {isEasterEggBox && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggBox === true}
                        onClose={() => {
                            setisEasterEggBox(false);
                            setuserAnswer11("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[10]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[10]?.name2}
                                        <br />
                                        {questsArray[10]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setuserAnswer11(e.target.value)
                                        }
                                        value={userAnswer11}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest11}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[10]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}
                {/* quest-12  div-------------------------------------------------------------------------------- */}
                {isEasterEggAddressBox && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggAddressBox === true}
                        onClose={() => {
                            setisEasterEggAddressBox(false);
                            setuserAnswer12("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[11]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[11]?.name2}
                                        <br />
                                        {questsArray[11]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setuserAnswer12(e.target.value)
                                        }
                                        value={userAnswer12}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest12}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[11]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}
                {/* quest-15  div-------------------------------------------------------------------------------- */}
                {isEasterEggPostageBox && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggPostageBox === true}
                        onClose={() => {
                            setisEasterEggPostageBox(false);
                            setuserAnswer12("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[14]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[14]?.name2}
                                        <br />
                                        {questsArray[14]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setuserAnswer15(e.target.value)
                                        }
                                        value={userAnswer15}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest15}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[14]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* quest-17  div-------------------------------------------------------------------------------- */}
                {isEasterEggLocationBox && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggLocationBox === true}
                        onClose={() => {
                            setisEasterEggLocationBox(false);
                            setuserAnswer17("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[16]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[16]?.name2}
                                        <br />
                                        {questsArray[16]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setuserAnswer17(e.target.value)
                                        }
                                        value={userAnswer17}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest17}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[16]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* quest-19  div-------------------------------------------------------------------------------- */}
                {isEasterEggQuest19Box && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggQuest19Box === true}
                        onClose={() => {
                            setisEasterEggQuest19Box(false);
                            setUserAnswer19("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[18]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[18]?.name2}
                                        <br />
                                        {questsArray[18]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setUserAnswer19(e.target.value)
                                        }
                                        value={userAnswer19}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest19}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[18]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}

    {/* quest-21  div-------------------------------------------------------------------------------- */}
                {isEasterEggQuest21Box && (
                    <Modal
                        className="w-full sm:w-[100%] lg:w-[100%] border-none items-center align-middle mx-auto outline-none justify-center bg-transparent "
                        open={isEasterEggQuest21Box === true}
                        onClose={() => {
                            setisEasterEggQuest21Box(false);
                            setUserAnswer21("");
                        }}
                        BackdropProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                backdropFilter: "blur(5px)",
                            },
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className=" 2xl:w-[30%]   xl:w-[40%] sm:w-[70%] md:w-[70%] lg:w-[70%] w-[90%] h-[400px] flex  duration-200 ease-in relative  outline-none  z-20 background-questreferral ">
                            <div className="absolute top-2 left-2 sm:top-4 sm:left-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {` QUEST #${questsArray[20]?.id}`}
                                </h5>
                            </div>
                            <div
                                className="items-center flex flex-col justify-center mx-auto w-full h-full gap-6  lg:gap-10"
                                id="all"
                            >
                                <div id="text">
                                    <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] flex-col font-medium">
                                        {questsArray[20]?.name2}
                                        <br />
                                        {questsArray[20]?.name}
                                    </h5>
                                </div>{" "}
                                <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                    <input
                                        className=" bg-transparent border w-[80%] lg:w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                        onChange={(e) =>
                                            setUserAnswer21(e.target.value)
                                        }
                                        value={userAnswer21}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                <div className="lg:w-1/3 w-1/2 mx-auto">
                                    <button
                                        onClick={updateQuest21}
                                        className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                            <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-14 flex justify-center items-center">
                                <h5 className="h10 text-center text-[15px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                                    {`POINTS: ${questsArray[20]?.points}`}
                                </h5>
                            </div>
                        </div>
                    </Modal>
                )}

                {isreferral &&
                    (currentUserName !== undefined ||
                        currentUserName !== null) && (
                        <Modal
                            className=" w-full sm:w-[100%] lg:w-[100%]  border-none  items-center align-middle  mx-auto outline-none justify-center  "
                            open={isreferral === true}
                            onClose={() => {
                                setisreferral(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className="h10 outline-none py-14 h-fit mx-auto gap-10 flex flex-col background-referral w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] 2xl:w-[30%] justify-between items-center align-middle ">
                                <div className="text-white mx-auto border-none rounded-lg outline-none w-full lg:px-24 ">
                                    <h4 className="text-center mb-7 text-black">
                                        The Mob looks out for family.
                                    </h4>
                                    <div className="flex items-center gap-3 justify-center align-middle mx-auto mb-5 ">
                                        <h5 className="text-[30px]">{`${myReferralCode}`}</h5>
                                        <button
                                            className=" px-3 py-1 rounded-lg text-[14px]  bg-black hover:bg-white hover:text-black duration-200 ease-in"
                                            onClick={copyReferralLink}
                                        >
                                            COPY
                                        </button>
                                    </div>{" "}
                                    {referralvalue === null ||
                                    referralvalue === undefined ? (
                                        <div className="flex flex-col items-start gap-3   w-full">
                                            <h5>
                                                {`1) Add your friend's referral id.`}
                                            </h5>
                                            <h5>
                                                {`2) Copy your referral id and give it to your friends.`}
                                            </h5>
                                            <h5>{`3) Enjoy the free points.`}</h5>
                                        </div>
                                    ) : (
                                        <h5 className="text-center">
                                            {`You were referred by ${referralvalue}.`}
                                        </h5>
                                    )}
                                </div>
                                {(referralvalue === null ||
                                    referralvalue === undefined) && (
                                    <div className="w-full flex align-middle items-center  justify-center gap-14 mx-auto lg:w-[100%] ">
                                        <input
                                            className=" bg-transparent border w-1/2 p-3 border-black  rounded-sm outline-none text-black placeholder:text-black/50 bg-white"
                                            onChange={(e) =>
                                                setreferralData(e.target.value)
                                            }
                                            value={referralData}
                                            style={{ color: "black" }}
                                            placeholder="Enter referral id"
                                        />
                                        <h5 className="">Points: 10</h5>
                                    </div>
                                )}
                                {(referralvalue === null ||
                                    referralvalue === undefined) && (
                                    <div className="w-1/2 mx-auto">
                                        <button
                                            onClick={updateRefferal}
                                            className="bg-black px-4 py-4 rounded-lg w-full text-white hover:bg-white hover:text-black duration-200 ease-in"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}

                {/* leaderboard div-------------------------------------------------------------------------------- */}
                <div
                    id="leaderboardDiv"
                    className="absolute transform -translate-x-1/2 -translate-y-1/2  top-[48%] left-[49%] sm:top-[48%] sm:left-[49%] md:top-[48%] md:left-[49%] lg:top-[63%] lg:left-[49%] xl:top-[64%] xl:left-[49%] 2xl:top-[66%] 2xl:left-[49%] flex-col flex gap-4 md:gap-10 justify-center items-center align-middle"
                >
                    <div className="hover:scale-105 duration-200 ease-in ">
                        <img
                            src={leaderboardbg}
                            className="w-[75px] sm:w-[80px] md:w-[130px]  lg:w-[190px] 2xl:w-[270px] cursor-pointer"
                            onClick={async () => {
                                handleFetchLeaderBoard();
                            }}
                            alt="leaderboardbg"
                        />
                    </div>

                    {isLeaderBoardOpen && (
                        <Modal
                            className=" w-full sm:w-[100%] lg:w-[100%]  border-none  items-center align-middle  mx-auto outline-none justify-center  "
                            open={isLeaderBoardOpen}
                            onClose={() => {
                                setisLeaderBoardOpen(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-white p-10   border-none rounded-lg outline-none h-[50%] xl:w-[80%] xl:h-[70%]  2xl:w-[70%] lg:w-[80%] lg:h-[60%] overflow-auto   background-star">
                                {isLeaderBoardLoading ? (
                                    <div className="text-white text-xl cursiveHandwriting mx-auto flex justify-center">
                                        <CircularProgress
                                            sx={{
                                                color: (theme) =>
                                                    theme.palette.grey[
                                                        theme.palette.mode ===
                                                        "light"
                                                            ? 800
                                                            : 800
                                                    ],
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="mx-auto w-full xl:w-[90%] lg:w-[100%] md:w-[80%] 2xl:w-[80%] sm:px-10 md:w-14px lg:px-14  ">
                                        <div className="  mx-y  mb-10  ">
                                            <p className="text-center font-bold text-white text-xl sm:text-2xl md:text-4xl h12 ">
                                                Leaderboard
                                            </p>
                                        </div>
                                        <div className="flex justify-between mx-auto mb-5 w-full  ">
                                            <h5 className=" sm:text-[15px] lg:text-[20px] xl:text-[25px] w-1/3 mx-auto text-center h12 text-white  ">
                                                Rank
                                            </h5>
                                            <h5 className="  sm:text-[15px] lg:text-[20px] xl:text-[25px] w-1/3 mx-auto   text-center  h12 text-white ">
                                                Name
                                            </h5>
                                            <h5 className="  sm:text-[15px] lg:text-[20px] xl:text-[25px] w-1/3 mx-auto   text-center  h12 text-white">
                                                Score
                                            </h5>
                                        </div>
                                        {!isLeaderBoardLoading && (
                                            <div>
                                                {sortedData
                                                    .slice(0, 10)
                                                    .map((y, index) => {
                                                        const twitterName =
                                                            y?.twitterName;
                                                        const discordName =
                                                            y?.discordName;
                                                        const userName =
                                                            twitterName ||
                                                            discordName;

                                                        // Check if the current leaderboard user matches the `testUsername`
                                                        const isTestUsername =
                                                            isUserInLeaderboards &&
                                                            (isUserInLeaderboards.twitterName ===
                                                                twitterName ||
                                                                isUserInLeaderboards.discordName ===
                                                                    discordName);

                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`${
                                                                    isTestUsername
                                                                        ? "bg-black/50"
                                                                        : ""
                                                                } flex mb-6 cursiveHandwriting mx-auto py-3 cursor-normal justify-center border border-transparent `}
                                                            >
                                                                <div className="flex w-1/3">
                                                                    <p className="text-white mx-auto h12 text-[10px] sm:text-[11px] md:text-[13px] lg:text-[14px] xl:text-[17px]">
                                                                        {getOrdinalSuffix(
                                                                            index +
                                                                                1
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="flex w-1/3">
                                                                    <p className="text-white md:mx-auto h12 text-[10px] sm:text-[11px] md:text-[13px] lg:text-[14px] xl:text-[17px]">
                                                                        {userName
                                                                            ? userName.length >=
                                                                              10
                                                                                ? userName
                                                                                      .slice(
                                                                                          0,
                                                                                          8
                                                                                      )
                                                                                      .toUpperCase() +
                                                                                  ".."
                                                                                : userName.toUpperCase()
                                                                            : null}
                                                                    </p>
                                                                </div>
                                                                <div className="flex w-1/3">
                                                                    <p className="text-white mx-auto h12 text-[10px] sm:text-[11px] md-text-[13px] lg-text-[14px] xl-text-[17px] 2xl:text-[20px]">
                                                                        {y?.totalScore !==
                                                                            null &&
                                                                        y?.totalScore !==
                                                                            undefined
                                                                            ? y.totalScore
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                        {(currentUserName !== undefined ||
                                            currentUserName !== null) &&
                                            !sortedData

                                                .slice(0, 10)
                                                .find(
                                                    (x) =>
                                                        isUserInLeaderboards &&
                                                        (isUserInLeaderboards.twitterName ===
                                                            x.twitterName ||
                                                            isUserInLeaderboards.discordName ===
                                                                x.discordName)
                                                ) &&
                                            isUserInLeaderboards && (
                                                <div
                                                    className={`bg-black/50  flex mb-6 cursiveHandwriting mx-auto py-3 cursor-normal justify-center border border-transparent `}
                                                >
                                                    <div className="flex w-1/3">
                                                        <p className="text-white mx-auto h12 text-[10px] sm:text-[11px] md:text-[13px] lg:text-[14px] xl:text-[17px]">
                                                            {getOrdinalSuffix(
                                                                sortedData.findIndex(
                                                                    (x) =>
                                                                        isUserInLeaderboards &&
                                                                        (isUserInLeaderboards.twitterName ===
                                                                            x.twitterName ||
                                                                            isUserInLeaderboards.discordName ===
                                                                                x.discordName)
                                                                ) + 1
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex w-1/3">
                                                        <p className="text-white md:mx-auto h12 text-[10px] sm:text-[11px] md:text-[13px] lg:text-[14px] xl:text-[17px]">
                                                            {isUserInLeaderboards.twitterName ||
                                                            isUserInLeaderboards.discordName
                                                                ? (
                                                                      isUserInLeaderboards.twitterName ||
                                                                      isUserInLeaderboards.discordName
                                                                  ).toUpperCase()
                                                                : "UNKNOWN"}
                                                        </p>
                                                    </div>
                                                    <div className="flex w-1/3">
                                                        <p className="text-white mx-auto h12 text-[10px] sm:text-[11px] md-text-[13px] lg-text-[14px] xl-text-[17px] 2xl:text-[20px]">
                                                            {isUserInLeaderboards.totalScore !==
                                                                null &&
                                                            isUserInLeaderboards.totalScore !==
                                                                undefined
                                                                ? isUserInLeaderboards.totalScore
                                                                : 0}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}
                </div>
                {/* card numbers buttons-------------------------------------------------------------------------------- */}
                <div
                    id="cardsDiv"
                    className={`absolute top-[43%] left-[49%] sm:top-[43%] md:top-[43%] md:left-[49%] lg:top-[50%] lg:left-[49%] xl:top-[50%] xl:left-[49%] 2xl:top-[50%] 2xl:left-[49%] flex-col   transform -translate-x-1/2 -translate-y-1/2 gap-5 flex  md:gap-2 lg:gap-10 xl:gap-14 
        ${
            isUserDataLoading ? " items-center  " : " items-center"
        }           justify-center  `}
                >
                    <div className="flex align-middle items-center justify-center ">
                        {!isUserDataLoading ? (
                            scoreArray.map((x, index) => (
                                <img
                                    key={index}
                                    src={x}
                                    className="xl:w-[80px] md:w-[40px] lg:w-[60px] w-[25px]"
                                    alt=""
                                />
                            ))
                        ) : (
                            <CircularProgress
                                sx={{
                                    color: (theme) =>
                                        theme.palette.grey[
                                            theme.palette.mode === "light"
                                                ? 800
                                                : 800
                                        ],
                                }}
                            />
                        )}
                        {/* {!isUserDataLoading && (
                            <i
                                className="fa-solid fa-rotate-right text-[15px] ml-1 md:text-[30px] 2xl:text-[80px] cursor-pointer"
                                onClick={async () => {
                                    await handleFetchQuests();
                                    await handleFetchUserData();
                                }}
                            ></i>
                        )} */}
                    </div>
                </div>
                {/* suits Faction-------------------------------------------------------------------------------- */}
                {/* <div id="suitsDiv">
                    <div
                        onClick={() => setsuits(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area sm:top-[58%] sm:left-[1%] lg:top-[58%] lg:left-[82%]  lg:h-[180px] lg:w-[180px]  sm:h-[120px] sm:w-[120px]"
                    ></div>{" "}
                    {suits && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={suits === true}
                            onClose={() => setsuits(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Customize the opacity here
                                    backdropFilter: "blur(5px)", // Optional: CSS blur effect for modern browsers
                                },
                            }}
                        >
                            <div className=" text-black w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper-connect">
                                {" "}
                                <p className="text-black cursiveHandwriting px-3 py-2 ">
                                    <p className="text-left italic font-bold text-black mb-2">
                                        {" "}
                                        “We know exactly who they are and how
                                        they think. They can’t even comprehend
                                        us.”
                                    </p>
                                    Slick back your hair, show off your tattoos.
                                    Walk dogs, wait tables, deliver the papers
                                    and add some truth to their propaganda. Stay
                                    subtle, always use ciphers. Stay invisible,
                                    just another kid working. Always watch,
                                    always listen. Take the information you
                                    gather and piece it together. The enemy’s
                                    planning something, go spy. They can’t catch
                                    you. You’re a Runner
                                </p>
                            </div>
                        </Modal>
                    )}
                </div> */}
                {/* posters Faction-------------------------------------------------------------------------------- */}
                {/* <div id="postersDiv">
                    <div
                        onClick={() => setposters(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area sm:top-[64%] sm:left-[17%] lg:top-[64%] lg:left-[27%]  lg:h-[120px] lg:w-[120px]  sm:h-[120px] sm:w-[120px]"
                    ></div>{" "}
                    {posters && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center"
                            open={posters === true}
                            onClose={() => setposters(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-black w-[90%] xl:w-[70%] border-none rounded-lg outline-none background-paper-connect py-10 md:pb-24">
                                <p className="text-black cursiveHandwriting px-3 py-2 ">
                                    <p className="text-left italic font-bold text-black mb-2">
                                        {" "}
                                        “We know exactly who they are and how
                                        they think. They can’t even comprehend
                                        us.”
                                    </p>
                                    Slick back your hair, show off your tattoos.
                                    Walk dogs, wait tables, deliver the papers
                                    and add some truth to their propaganda. Stay
                                    subtle, always use ciphers. Stay invisible,
                                    just another kid working. Always watch,
                                    always listen. Take the information you
                                    gather and piece it together. The enemy’s
                                    planning something, go spy. They can’t catch
                                    you. You’re a Runner
                                </p>
                            </div>
                        </Modal>
                    )}
                </div> */}
                {/* newspaper Faction-------------------------------------------------------------------------------- */}
                {/* <div id="newspaperDiv">
                    <div
                        className="interactive-area sm:top-[58%] sm:left-[80%] lg:top-[60%] lg:left-[5%]  lg:h-[120px] lg:w-[120px]  sm:h-[120px] sm:w-[120px]"
                        onClick={() => setnewspaper(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                    {newspaper && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={newspaper === true}
                            onClose={() => setnewspaper(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Customize the opacity here
                                    backdropFilter: "blur(5px)", // Optional: CSS blur effect for modern browsers
                                },
                            }}
                        >
                            <div className=" text-black w-1/4 border-none rounded-lg outline-none background-paper-connect">
                                {" "}
                                <p className="text-black cursiveHandwriting px-3 py-2 ">
                                    <p className="text-left italic font-bold text-black mb-2">
                                        {" "}
                                        “We know exactly who they are and how
                                        they think. They can’t even comprehend
                                        us.”
                                    </p>
                                    Slick back your hair, show off your tattoos.
                                    Walk dogs, wait tables, deliver the papers
                                    and add some truth to their propaganda. Stay
                                    subtle, always use ciphers. Stay invisible,
                                    just another kid working. Always watch,
                                    always listen. Take the information you
                                    gather and piece it together. The enemy’s
                                    planning something, go spy. They can’t catch
                                    you. You’re a Runner
                                </p>
                            </div>
                        </Modal>
                    )}
                </div> */}
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    pageName={pageName}
                    isbankcasinoviewanimating={isbankcasinoviewanimating}
                    setisbankcasinoviewanimating={setisbankcasinoviewanimating}
                    setPageName={setPageName}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isMusicPlayed={isMusicPlayed}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                />
            </div>

            <CustomAlert alert={alert} hideAlert={hideAlert} />
        </div>
    );
}

export default BankWhitelistView;
