import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import "./bank.css";
import gsap from "gsap";
import bridgemain from "../../../../assets/images/bridgemain.png";
import hillbilliesbg from "../../../../assets/images/factions/hillbillies.png";
import deadbody from "../../../../assets/images/deadbody.jfif";
import bridgemobile from "../../../../assets/images/bridgemobile.png";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import closed from "../../../../assets/images/closed.png";
function BridgeView({
    data,

    setisElementsHidden,
    handleBankCasinoView,
    isElementsHidden,
    handleHide,

    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    audioRef,
    playMusic,
    isLargeScreen,
    setIsLargeScreen,
}) {
    const containerRef = useRef(null);
    const [newspaper, setnewspaper] = useState(false);
    const [pizza, setpizza] = useState(false);
    const [casinoView, setcasinoView] = useState(false);
    const [boots, setboots] = useState(false);
    const [hillbillies, sethillbillies] = useState(false);
    const [toys, settoys] = useState(false);
    const [casino, setcasino] = useState(false);
    const [isCasinoAnimating, setisCasinoAnimating] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);

    const [pizzachildModalOpen, setpizzachildModalOpen] = useState(false);
    const [bootsModalOpen, setbootsModalOpen] = useState(false);
    const [hillbilliesModalOpen, sethillbilliesModalOpen] = useState(false);
    const [toysModalOpen, settoysModalOpen] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        gsap.set("#bridgeview", { scale: 1, opacity: 1 });

        // Your existing animation trigger
        if (isCasinoAnimating === true) {
            gsap.to("#bridgeview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/casino");
                },
            });
        }

        return () => {
            gsap.set("#bridgeview", { scale: 1, opacity: 1 });
        };
    }, [isCasinoAnimating]);

    useEffect(() => {
        if (newspaper || pizza || boots || toys || casino || hillbillies) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [newspaper, pizza, boots, toys, casino, hillbillies]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);
     
            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bridgeview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        if (isAnimating === true) {
            gsap.to("#bridgeview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    return (
        <div
            ref={containerRef}
            className="relative z-10 min-h-screen max-h-screen flex flex-col items-center mx-auto overflow-hidden md:overflow-auto w-full  "
            id="bridgeview"
        >
            <div className="relative max-h-screen w-full overflow-hidden">
                {isLargeScreen ? (
                    <img
                        src={bridgemain}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={bridgemobile}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}
                {/* boots Faction-------------------------------------------------------------------------------- */}
                <div id="bootsDiv">
                    <div
                        onClick={() => setbootsModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[65%] left-[9%] h-[40px] w-[40px] md:top-[85%] md:left-[10%]  lg:top-[81%] lg:left-[11%]  lg:h-[180px] lg:w-[180px]  md:h-[120px] md:w-[120px]"
                    ></div>{" "}
                    {bootsModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={bootsModalOpen}
                            onClose={() => setbootsModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setboots(true);
                                    setbootsModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {boots && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={boots === true}
                            onClose={() => setboots(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent w-full  xl:w-full border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setboots(false)}
                                ></i>{" "} */}
                                <p className="text-transparent   cursiveHandwriting  ">
                                    <p className="text-center italic text-transparent mb-2 cursiveHandwriting  font-bold">
                                        {" "}
                                        “I wish Lucky were around to see what
                                        we've become."
                                    </p>
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* hillbillies Faction-------------------------------------------------------------------------------- */}
                <div id="hillbilliesDiv">
                    <div
                        onClick={() => sethillbilliesModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[65%] left-[0%] h-[40px] w-[40px] md:top-[89%] md:left-[0%]  lg:top-[83%] lg:left-[0%]  lg:h-[130px] lg:w-[130px]  md:h-[100px] md:w-[100px]"
                    ></div>{" "}
                    {hillbilliesModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={hillbilliesModalOpen}
                            onClose={() => sethillbilliesModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    sethillbillies(true);
                                    sethillbilliesModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {hillbillies && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={hillbillies === true}
                            onClose={() => sethillbillies(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent py-14 pb-24 sm:py-24 md:px-24  w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => sethillbillies(false)}
                                ></i> */}
                                <Fade>
                                    {" "}
                                    <img
                                        src={hillbilliesbg}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]   sm:h-[200px] lg:h-[300px] mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade>
                                <p className="text-transparent px-3 py-2 cursiveHandwriting ">
                                    <p className="text-center italic text-transparent mb-2 font-bold ">
                                        {" "}
                                        “Step one’s delicious. Step two’s
                                        smuggling it in.”
                                    </p>
                                    Tangle your beard and breathe in the fumes.
                                    Start with 5 lbs of sugar, 4 gallons
                                    distilled water, 2.5 lbs of coarse ground
                                    cornmeal, small amounts of yeast, and 3.3
                                    lbs of ground barley. You boil a gallon, add
                                    cornmeal and stir. Next you cut the heat,
                                    add the rest of the water to mix with the
                                    sugar and malt. Wait for it to cool. Then
                                    you let it ferment in the open air. Move it
                                    to the still. You make the booze smooth as
                                    silk, but it kicks like a mule. You’re a
                                    Hillbilly.
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* pizza Faction-------------------------------------------------------------------------------- */}
                <div id="pizzaDiv">
                    <div
                        onClick={() => setpizzachildModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[51%] right-[17%] h-[60px] w-[60px] md:top-[60%] md:left-[66%] lg:top-[64%] lg:left-[72%]  lg:h-[120px] lg:w-[120px]  md:h-[120px] md:w-[120px]"
                    ></div>{" "}
                    {pizzachildModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={pizzachildModalOpen}
                            onClose={() => setpizzachildModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setpizza(true);
                                    setpizzachildModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {pizza && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center"
                            open={pizza === true}
                            onClose={() => setpizza(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent w-full  xl:w-full border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setpizza(false)}
                                ></i>{" "} */}
                                <p className="text-transparent px-3 text-sm py-2 cursiveHandwriting">
                                    <p className="text-center italic  text-transparent mb-2 cursiveHandwriting font-bold">
                                        {" "}
                                        “Infamous pizzas, outrageous prices.”
                                    </p>
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* toys Faction-------------------------------------------------------------------------------- */}
                <div id="toysDiv">
                    <div
                        className="interactive-area  top-[56%] right-[0%] h-[60px] w-[60px] md:top-[70%] md:right-[6%] lg:top-[70%] lg:left-[90%]  lg:h-[120px] lg:w-[120px]  md:h-[120px] md:w-[120px]"
                        onClick={() => settoysModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                    {toysModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={toysModalOpen}
                            onClose={() => settoysModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    settoys(true);
                                    settoysModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {toys && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={toys === true}
                            onClose={() => settoys(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent w-full border-none rounded-lg outline-none background-paper">
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => settoys(false)}
                                ></i>{" "} */}
                                <p className="text-center italic font-bold text-transparent mb-2 cursiveHandwriting ">
                                    {" "}
                                    "World peace comes after revolution."
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* CasinoView Faction-------------------------------------------------------------------------------- */}

                <div id="casinoViewDiv">
                    {!casinoView && (
                        <div
                            onClick={() => setisCasinoAnimating(true)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="interactive-area top-[58%] left-[16%] h-[40px] w-[40px] md:top-[67%] md:left-[13%] lg:top-[60%] lg:left-[12%]  xl:top-[62%] xl:left-[15%] lg:h-[200px] lg:w-[200px]   md:h-[120px] md:w-[120px] "
                        ></div>
                    )}
                </div>
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isMusicPlayed={isMusicPlayed}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                />
            </div>
        </div>
    );
}

export default BridgeView;
