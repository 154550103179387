import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import suitsbg from "../../../../assets/images/factions/suits.png";
import closed from "../../../../assets/images/closed.png";
import "./bank.css";
import deadbody from "../../../../assets/images/deadbody.jfif";
import gsap from "gsap";
import bankview from "../../../../assets/images/citypc.jpg";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import posterpic from "../../../../assets/images/posterpic.jfif";
import bankmobile from "../../../../assets/images/citymob.jpg";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
function BankCityView({
    data,
    handleBank,
    pageName,
    setisElementsHidden,
    isElementsHidden,
    handleHide,
    setPageName,
    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    isLargeScreen,
    setIsLargeScreen,
    audioRef,
    playMusic,
}) {
    const containerRef = useRef(null);
    const [newspaper, setnewspaper] = useState(false);
    const [posters, setposters] = useState(false);
    const [suits, setsuits] = useState(false);

    const [newspaperModalOpen, setnewspaperModalOpen] = useState(false);
    const [postersModalOpen, setpostersModalOpen] = useState(false);
    const [suitsModalOpen, setsuitsModalOpen] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (newspaper || posters || suits) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [newspaper, posters, suits]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);

            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bankcityview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        // Your existing animation trigger
        if (isAnimating === true) {
            gsap.to("#bankcityview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    return (
        <div
            ref={containerRef}
            className="relative min-h-screen flex flex-col items-center mx-auto overflow-hidden md:overflow-auto w-full  "
            id="bankcityview"
        >
            <div className="relative max-h-screen">
                {isLargeScreen ? (
                    <img
                        src={bankview}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={bankmobile}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    pageName={pageName}
                    setPageName={setPageName}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                    isMusicPlayed={isMusicPlayed}
                />
            </div>
        </div>
    );
}

export default BankCityView;
