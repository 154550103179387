import React, { useState } from "react";
import Modal from "@mui/material/Modal";

export const useAlert = () => {
  const [alert, setAlert] = useState(null);

  const showAlert = (message, title = "title", showOkay = true) => {
    setAlert({ message, title, showOkay });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return { alert, showAlert, hideAlert };
};

export const usePrompt = () => {
  // onclicking okay button, the prompt will be hidden with boolean value
  const [prompt, setPrompt] = useState(null);

  const [resolveFn, setResolveFn] = useState(null); // Store the resolve function of the promise

  const showPrompt = async (message, title = "title") => {
    setPrompt({ message, title });
    return new Promise((resolve) => {
      setResolveFn(() => resolve);
    });
  };

  const hidePrompt = (value) => {
    if (resolveFn) {
      resolveFn(value);
      setResolveFn(null);
    }
    setPrompt(null);
  };

  return { prompt, showPrompt, hidePrompt };
};

export const CustomPrompt = ({ prompt, hidePrompt, showSample }) => {
  if (!prompt) return null;

  return (
    <Modal
      className=" w-fit border-none  mx-auto outline-none justify-center  "
      open={true}
      onClose={() => hidePrompt(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(5px)",
        },
      }}
    >
      <div className=" text-black flex flex-col items-center p-10 px-24 w-full border-none rounded-lg outline-none  background-paper">
        <div className="text-black text-2xl cursiveHandwriting px-1 py-2 ">
          {prompt.title}
        </div>
        <div className="cursiveHandwriting text-black text-xl px-1 py-2">
          {prompt.message}
        </div>
        <div className="flex gap-3 text-black text-lg px-1 py-2">
          <button
            className="cursiveHandwriting button1 !border p-2 !border-black hover:text-white hover:bg-black rounded-lg mt-2"
            onClick={() => {
              hidePrompt(true);
            }}
          >
            Yes
          </button>
          <button
            className="cursiveHandwriting button1 !border p-2 !border-black hover:text-white hover:bg-black rounded-lg mt-2"
            onClick={() => hidePrompt(false)}
          >
            No !!
          </button>
        </div>
      </div>
    </Modal>
  );
};

/**
 * @typedef {Object} Alert
 * @property {string} message
 * @property {string} title
 */
export const CustomAlert = ({ alert, hideAlert, showOkay }) => {
  if (!alert) return null;

  return (
    <Modal
      className=" w-fit border-none  mx-auto outline-none  justify-center  "
      open={true}
      onClose={() => hideAlert()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          backdropFilter: "blur(5px)",
        },
      }}
    >
      <div className=" text-black flex flex-col items-center px-10 lg:px-24 sm:w-full w-screen border-none rounded-lg outline-none  background-paper">
        <div className="text-black text-xl cursiveHandwriting  py-2 ">
          {alert.title}
        </div>
        <div className="cursiveHandwriting text-black text-md  py-2  w-full text-center">
          {alert.message}
        </div>
        {showOkay && (
          <div className="text-black text-lg  py-2">
            <button
              className="cursiveHandwriting button1 !border p-2 !border-black hover:text-white hover:bg-black rounded-lg mt-2"
              onClick={() => hideAlert()}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
