import React, { useEffect, useState } from "react";
import navbarPic from "../../../../src/assets/images/art/artbg.jpg";
import SplitText from "gsap/SplitText";
import logomob from "../../../../src/assets/icons/logomoborange.png";
import aboutimage from "../../../../src/assets/icons/About.png";
import discordlogo from "../../../../src/assets/icons/Discord logo circle.png";
import xlogo from "../../../assets/icons/Xlogo.png";
import moblistbutton from "../../../assets/icons/Moblist.png";
import howitworksimage from "../../../assets/icons/How ir works.png";
import Marquee from "react-fast-marquee";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
function MobileNavbar({ setPageName, setIsMenuOpen, isMenuOpen, pageName }) {
    const [targetElementId, setTargetElementId] = useState(null);
    const [aboutUs, setAboutUs] = useState(false);
    const [howItWorks, setHowItWorks] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavToHome = (id) => {
        gsap.to("#mobileNavbar", {
            y: "-100%",
            opacity: 0,
            // yoyo: true,
            duration: 0.5,
            ease: "ease",
            onComplete: () => {
                setIsMenuOpen(false);
            },
        });
    };
    useEffect(() => {
        if (isMenuOpen === true) {
            gsap.from("#mobileNavbar", {
                y: "-100%",
                opacity: 0,
                // yoyo: true,
                duration: 0.5,
                ease: "ease",
            });
        }
    }, [isMenuOpen]);
    useEffect(() => {
        if (targetElementId) {
            window.history.replaceState(
                null,
                null,
                `?targetElement=${targetElementId}`
            );
        }
    }, [targetElementId]);
    useEffect(() => {
        if (aboutUs || howItWorks) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [aboutUs, howItWorks]);
    return (
        <div
            className="h-screen min-h-screen max-h-screen   overflow-hidden relative bg-[#e7dec2] p-4"
            id="mobileNavbar"
        >
            {" "}
            {howItWorks && (
                <Modal
                    className=" w-full border-none  mx-auto outline-none justify-center  "
                    open={howItWorks === true}
                    onClose={() => setHowItWorks(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            backdropFilter: "blur(5px)",
                        },
                    }}
                >
                    <div className=" text-black w-full border-none lg:px-14 rounded-lg outline-none   background-paper">
                        {" "}
                        <p className="text-black  px-10  py-2 cursiveHandwriting ">
                            Welcome to the world of The Mob. Hidden in the
                            background are a number of interactable items. Your
                            task is simply to find and read them. What you glean
                            from them is entirely up to you.<br></br> <br></br>{" "}
                            For those that see, we’ll be waiting.
                        </p>
                    </div>
                </Modal>
            )}
            {aboutUs && (
                <Modal
                    className="w-fit border-none mx-auto outline-none justify-center"
                    open={aboutUs === true}
                    onClose={() => setAboutUs(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            backdropFilter: "blur(5px)",
                        },
                    }}
                >
                    <div className="text-black border-none py-14 lg:px-14 object-contain rounded-lg outline-none overflow-hidden background-paper">
                        {/* <i
                className="fa-solid fa-xmark fa-xl absolute top-5 right-5 cursor-pointer hover:scale-125 text-[#bf5331] duration-200 ease-in"
                onClick={() => setAboutUs(false)}
            ></i> */}

                        <p className="text-transparent text-[11px] cursiveHandwriting px-1 ">
                            Some of you might be early Bitcoin adopters, way
                            back from the small price of hundreds or thousands.
                            We salute you and your wisdom. Some others might be
                            new, freshly graduated from Coinbase, and excited to
                            dive into the depths. We welcome you and your
                            enthusiasm. Where we’re in harmony is in our shared
                            fascination with Bitcoin. Its evolution–from a proof
                            of concept to an internet black-market coin to the
                            legitimized, institutionally accepted currency it is
                            today–has captured the eyes of millions.
                            <br />
                            <br />
                            The thousands of published books and innumerable
                            internet tall tales authored in its name have
                            already ensured that Bitcoin will never be
                            forgotten. It's mythical, the stuff of legend. But
                            its history is still in the making. This story’s ink
                            is still wet, there are countless pages just waiting
                            to be filled. Well, we intend to pen a few letters.
                            We’ll chisel them, inscribe them immutably, onto the
                            very foundations of the blockchain so we might add
                            our story to its own. In this way, we’ll become
                            forever the authors of Bitcoin’s tale.
                            <br />
                            <br />
                            That is immortality. That is our dream. We’re not
                            there yet, but together with all of you, we will be.
                            <br />
                            <br />
                            We are one, and we invite you all to join us.
                            <br />
                            <br />
                            Signed,
                            <br />
                            The Mob
                        </p>
                    </div>
                </Modal>
            )}
            <div className="flex md:flex-row justify-between gap-10 p-2 md:p-10 z-20 ">
                <div className="z-20">
                    <img src={logomob} alt="" className="w-[100px]" />
                </div>
                <div className="flex items-center justify-center flex-row align-middle gap-2 z-20 text-white cursor-pointer">
                    <i
                        className="fa-solid fa-xmark fa-xl cursor-pointer hover:scale-125  text-[#bf5331] duration-200 ease-in"
                        onClick={handleNavToHome}
                    ></i>
                </div>
            </div>
            <div className="flex md:flex-row  flex-col justify-around items-center align-middle z-20">
                <div className="flex flex-col gap-5 align-middle items-center z-20">
                    <img
                        onClick={() => {
                            navigate("/whitelist");
                            if (location.pathname === "/whitelist") {
                                setIsMenuOpen(false);
                            }
                        }}
                        src={moblistbutton}
                        alt="moblist button"
                        className="w-[140px] duration-150 cursor-pointer p-4 ease-in hover:scale-105"
                    />

                    <img
                        src={aboutimage}
                        className="w-[150px] hover:scale-105 duration-150 ease-in rounded-md p-4 cursor-pointer"
                        alt="about "
                        onClick={() => setAboutUs(true)}
                    />
                    <img
                        src={howitworksimage}
                        className="w-[200px] hover:scale-105 duration-150 ease-in rounded-md p-4 cursor-pointer"
                        alt="how it works "
                        onClick={() => setHowItWorks(true)}
                    />

                    <div className="flex flex-col align-middle items-center justify-center">
                        <div className="flex p-4 justify-center align-middle items-center gap-10">
                            <a
                                href="https://discord.gg/themobonbtc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={discordlogo}
                                    alt="discord logo"
                                    className=" w-10 duration-150 ease-in hover:scale-105"
                                />
                            </a>
                            <a
                                href="https://twitter.com/themobonbtc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={xlogo}
                                    alt="x logo"
                                    className="w-10 duration-150 ease-in hover:scale-105"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="w-[90%]  mt-10 border border-black mx-auto" />
            <div className="mt-10 z-20">
                <Marquee speed={200} loop={0}>
                    <h1>THE MOB</h1>
                </Marquee>
            </div>
            <div
                data-depth="0.1"
                className="absolute inset-0 overflow-hidden z-0"
            ></div>{" "}
        </div>
    );
}

export default MobileNavbar;
