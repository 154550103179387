import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import suitsbg from "../../../../assets/images/factions/suits.png";
import closed from "../../../../assets/images/closed.png";
import "./bank.css";
import deadbody from "../../../../assets/images/deadbody.jfif";
import gsap from "gsap";
import bankview from "../../../../assets/images/speakeasypc.jpg";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import posterpic from "../../../../assets/images/posterpic.jfif";
import bankmobile from "../../../../assets/images/speakeasymob.jpg";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
function BankSpeakEasyView({
    data,
    handleBank,
    pageName,
    setisElementsHidden,
    isElementsHidden,
    handleHide,
    setPageName,
    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    isLargeScreen,
    setIsLargeScreen,
    audioRef,
    playMusic,
}) {
    const containerRef = useRef(null);
    const [newspaper, setnewspaper] = useState(false);
    const [posters, setposters] = useState(false);
    const [flowers, setflowers] = useState(false);

    const [newspaperModalOpen, setnewspaperModalOpen] = useState(false);
    const [postersModalOpen, setpostersModalOpen] = useState(false);
    const [flowersModalOpen, setflowersModalOpen] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (newspaper || posters || flowers) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [newspaper, posters, flowers]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);

            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bankview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        // Your existing animation trigger
        if (isAnimating === true) {
            gsap.to("#bankview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    return (
        <div
            ref={containerRef}
            className="relative min-h-screen flex flex-col items-center mx-auto overflow-hidden md:overflow-auto w-full  "
            id="bankview"
        >
            <div className="relative max-h-screen">
                {isLargeScreen ? (
                    <img
                        src={bankview}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={bankmobile}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}

                {/* suits Faction-------------------------------------------------------------------------------- */}
                <div id="flowersDiv">
                    <div
                        onClick={() => setflowersModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[58%] left-[45%] h-[60px] w-[60px] md:top-[58%] md:right-[1%] lg:top-[56%] lg:left-[42%]   xl:top-[56%] xl:left-[47%]  lg:h-[180px] lg:w-[180px]  md:h-[120px] md:w-[120px] "
                    ></div>{" "}
                    {flowersModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={flowersModalOpen}
                            onClose={() => setflowersModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setflowers(true);
                                    setflowersModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {flowers && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={flowers === true}
                            onClose={() => setflowers(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent pb-14 sm:px-14 sm:py-14 w-full xl:px-24 xl:w-1/2 border-none rounded-lg outline-none background-paper ">
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            Family,
                                        </span>
                                    </p>
                                    <br></br> <br></br>
                                </div>
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            If
                                        </span>
                                        it hadn’t been for your rescue, I’d be
                                        in a cell by now. They grabbed me right
                                        off the street, I never saw them coming.
                                        It was Charles, the man from Tony’s
                                        journal. He’s a problem that we have to
                                        solve. But that’s tomorrow. Today, we
                                        celebrate.
                                    </p>
                                </div>
                                <br></br>
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            I
                                        </span>
                                        owe you my life, fellas, and a mobster
                                        always pays his debts. Take this code to
                                        start - 1155771
                                    </p>
                                </div>
                                <br></br>
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            This
                                        </span>
                                        is a lesson for me—I got sloppy, it
                                        won’t happen again—but it’s also an
                                        inspiration. Because when shit hit the
                                        fan, my family was there. The Mob has no
                                        bosses, just brothers.
                                    </p>
                                </div>
                                <br></br>
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            Signed,
                                        </span>
                                    </p>
                                </div>
                                <br></br>
                                <div className="flex">
                                    <p className="cursiveHandwriting ">
                                        <span className="indent-6  text-black font-bold text-[13px] sm:text-[15px] md:text-[14px] lg:text-[18px] mr-1">
                                            Nymos
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    pageName={pageName}
                    setPageName={setPageName}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                    isMusicPlayed={isMusicPlayed}
                />
            </div>
        </div>
    );
}

export default BankSpeakEasyView;
