import React, { useState, useEffect, useRef } from "react";
import aboutimage from "../../../../assets/icons/About.png";
import discordlogo from "../../../../assets/icons/Discord logo circle.png";

import xlogo from "../../../../assets/icons/X button.png";
import Modal from "@mui/material/Modal";
import { Tooltip } from "@mui/material";
import SplitText from "gsap/SplitText";
import backbutton from "../../../../assets/icons/backbutton.png";
import moblistbutton from "../../../../assets/icons/Moblist.png";
import howitworksimage from "../../../../assets/icons/How ir works.png";
import gsap from "gsap";
import MobileNavbar from "../../../shared/navbar/MobileNavbar";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function BankNavbar({
    data,
    setIsAnimating,
    pageName,
    setPageName,
    isElementsHidden,
    handleHide,
    playMusic,
    isMusicPlayed,
    setisbankcasinoviewanimating,
    isbankcasinoviewanimating,
    isLargeScreen,
    setIsLargeScreen,

}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [aboutUs, setAboutUs] = useState(false);
    const [howItWorks, setHowItWorks] = useState(false);
    const location = useLocation();
    const menuRef = useRef();
    const navLinks = data?.navLinks;
    const navigate = useNavigate();
    const handleBackImageClick = () => {
        // Check if pageName is not any of the specified names
        if (
            location.pathname !== "/whitelist" &&
            location.pathname !== "/slot"
        ) {
            setIsAnimating(true);
        } else {
            setisbankcasinoviewanimating(true);
        }
    };

    useEffect(() => {
        gsap.set(menuRef.current, { y: "-100%" });
        console.log(menuRef.current);
    }, []);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    useEffect(() => {
        if (aboutUs || howItWorks) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [aboutUs, howItWorks]);

    if (isMenuOpen && !isLargeScreen) {
        return (
            <MobileNavbar
                setIsLargeScreen={setIsLargeScreen}
                isLargeScreen={setIsLargeScreen}
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
            />
        );
    }

    return (
        <div
            className={` items-center align-middle w-full md:w-[100%] max-w-[100%]  mx-auto py-2 relative pt-3  ${
                !isElementsHidden ? "bg-black/70" : "bg-transparent"
            }   z-0   `}
        >
            {howItWorks && isLargeScreen && (
                <Modal
                    className=" w-fit border-none  mx-auto outline-none justify-center  "
                    open={howItWorks === true}
                    onClose={() => setHowItWorks(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            backdropFilter: "blur(5px)",
                        },
                    }}
                >
                    <div className=" text-transparent  w-1/2 border-none lg:px-14 rounded-lg outline-none   background-paper">
                        {" "}
                        <p className="text-transparent   px-14 md:px-14 lg:px-14 sm:px-14 sm:py-14  py-2 cursiveHandwriting ">
                            Welcome to the world of The Mob. Hidden in the
                            background are a number of interactable items. Your
                            task is simply to find and read them. What you glean
                            from them is entirely up to you.<br></br> <br></br>{" "}
                            For those that see, we’ll be waiting.
                        </p>
                    </div>
                </Modal>
            )}
            {aboutUs && isLargeScreen && (
                <Modal
                    className=" w-fit border-none  mx-auto outline-none justify-center  "
                    open={aboutUs === true}
                    onClose={() => setAboutUs(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    BackdropProps={{
                        style: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            backdropFilter: "blur(5px)",
                        },
                    }}
                >
                    <div className=" text-transparent   overflow-hidden  md:px-14 lg:px-14 sm:px-14  w-full  xl:w-1/2 border-none rounded-lg py-24 outline-none background-paper">
                        <p className="text-transparent text-[12px] md:text-[16px]  px-4 md:px-14 lg:px-5 sm:px-5  cursiveHandwriting ">
                            Some of you might be early Bitcoin adopters, way
                            back from the small price of hundreds or thousands.
                            We salute you and your wisdom. Some others might be
                            new, freshly graduated from Coinbase, and excited to
                            dive into the depths. We welcome you and your
                            enthusiasm. Where we’re in harmony is in our shared
                            fascination with Bitcoin. Its evolution–from a proof
                            of concept to an internet black-market coin to the
                            legitimized, institutionally accepted currency it is
                            today has captured the eyes of millions.
                            <br></br> <br></br> The thousands of published books
                            and innumerable internet tall tales authored in its
                            name have already ensured that Bitcoin will never be
                            forgotten. It's mythical, the stuff of legend. But
                            its history is still in the making. This story’s ink
                            is still wet, there are countless pages just waiting
                            to be filled. Well, we intend to pen a few letters.
                            We’ll chisel them, inscribe them immutably, onto the
                            very foundations of the blockchain so we might add
                            our story to its own. In this way, we’ll become
                            forever the authors of Bitcoin’s tale. <br></br>{" "}
                            <br></br> That is immortality. That is our dream.
                            We’re not there yet, but, together with all of you,
                            we will be. <br></br> <br></br> We are one, and we
                            invite you all to join us. <br></br> <br></br>{" "}
                            Signed, <br></br> <br></br> The Mob
                        </p>
                    </div>
                </Modal>
            )}

            <div className="flex justify-between items-center  lg:px-24 px-6 ">
                {isElementsHidden === false && (
                    <div className="  ">
                        {" "}
                        {!isMusicPlayed ? (
                            <div className=" z-40 ">
                                {" "}
                                <i
                                    onClick={playMusic}
                                    className="fa-solid fa-volume-xmark z-[100]  cursor-pointer text-white fa-lg hover:scale-125  hover:text-[#bf5331] duration-200 ease-in hover:-rotate-12"
                                ></i>{" "}
                            </div>
                        ) : (
                            <div className=" z-40 ">
                                {" "}
                                <i
                                    onClick={playMusic}
                                    className="fa-solid fa-volume-off z-[100]  cursor-pointer text-white fa-lg hover:scale-125  hover:text-[#bf5331] duration-200 ease-in  hover:-rotate-6"
                                ></i>
                            </div>
                        )}
                    </div>
                )}
                {isElementsHidden === false &&
                    (isLargeScreen ? (
                        <div className="flex gap-10 align-middle justify-center items-center    ">
                            <img
                                onClick={handleBackImageClick}
                                src={backbutton}
                                alt="back button"
                                className="w-10 duration-150 cursor-pointer ease-in hover:scale-105"
                                style={{
                                    visibility:
                                        location.pathname === "/"
                                            ? "hidden"
                                            : "visible",
                                    pointerEvents:
                                        location.pathname === "/"
                                            ? "none"
                                            : "auto",
                                }}
                            />
                            <a
                                href="https://twitter.com/themobonbtc"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-10"
                            >
                                <img
                                    src={xlogo}
                                    alt="x logo"
                                    className="duration-150 ease-in hover:scale-105"
                                />
                            </a>
                            <img
                                src={aboutimage}
                                className="w-[120px] hover:scale-105 duration-150 ease-in rounded-md  cursor-pointer"
                                alt="about "
                                onClick={() => setAboutUs(true)}
                            />{" "}
                            <img
                                onClick={() => navigate("/whitelist")}
                                src={moblistbutton}
                                alt="back button"
                                className="w-[110px] duration-150 cursor-pointer ease-in hover:scale-105"
                            />
                            <img
                                src={howitworksimage}
                                className="w-[180px] hover:scale-105 duration-150 ease-in rounded-md cursor-pointer"
                                alt="how it works "
                                onClick={() => setHowItWorks(true)}
                            />
                            <a
                                href="https://discord.gg/themobonbtc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={discordlogo}
                                    alt="discord logo"
                                    className=" w-10 duration-150 ease-in hover:scale-105"
                                />
                            </a>
                        </div>
                    ) : (
                        <div className="">
                            <i
                                className="fa-solid fa-bars cursor-pointer bg-black px-1 py-3 items-center text-white fa-lg"
                                onClick={handleMenuOpen}
                            ></i>
                        </div>
                    ))}
                {location.pathname !== "/" &&
                    isElementsHidden === false &&
                    !isLargeScreen && (
                        <img
                            onClick={handleBackImageClick}
                            src={backbutton}
                            alt="back button"
                            className="w-8 duration-150 cursor-pointer ease-in hover:scale-105"
                        />
                    )}
                <div className=" justify-end flex ">
                    <i
                        onClick={handleHide}
                        className={`${
                            !isElementsHidden
                                ? "  fa-lg fa-solid fa-expand text-white hover:scale-125 cursor-pointer md:hover:text-[#bf5331] duration-200 ease-in md:hover:-rotate-6"
                                : "absolute md:top-10 top-6 right-5 md:right-[97px] fa-lg fa-solid fa-expand text-white hover:scale-125 cursor-pointer md:hover:text-[#bf5331] duration-200 ease-in md:hover:-rotate-6"
                        }`}
                    ></i>
                </div>
            </div>
        </div>
    );
}

export default BankNavbar;
