import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import "./bank.css";
import gsap from "gsap";
import bankview from "../../../../assets/images/slot/slot.gif";
import staticfaction from "../../../../assets/images/factions/staticfaction.gif";
import staticmobfaction from "../../../../assets/images/factions/staticmobfaction.gif";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import mechanicspc from "../../../../assets/images/factions/The Mechanics.gif";
import mechanicsmob from "../../../../assets/images/factions/The Mechanicsmob.gif";
import soldierspc from "../../../../assets/images/factions/The Soldiers.gif";
import soldiersmob from "../../../../assets/images/factions/The Soldiersmob.gif";
import hillbilliespc from "../../../../assets/images/factions/The Hillbillies.gif";
import hillbilliesmob from "../../../../assets/images/factions/The Hillbilliesmob.gif";
import suitspc from "../../../../assets/images/factions/The Suits.gif";
import suitsmob from "../../../../assets/images/factions/The Suitsmob.gif";
import runnerspc from "../../../../assets/images/factions/The Runners.gif";
import runnersmob from "../../../../assets/images/factions/The Runnersmob.gif";
import axios from "axios";
import {
    useAlert,
    CustomAlert,
    CustomPrompt,
    usePrompt,
} from "../../../shared/CustomAlert";
import { useNavigate } from "react-router-dom";
function BankSlotMachineView({
    data,

    setisElementsHidden,
    isElementsHidden,

    isLargeScreen,
    setIsLargeScreen,
    handleHide,

    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    audioRef,
    playMusic,
}) {
    const containerRef = useRef(null);
    const [runslot, setrunslot] = useState(false);
    const [isslotAnimating, setisslotAnimating] = useState(false);
    const [isbankcasinoviewanimating, setisbankcasinoviewanimating] =
        useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (isslotAnimating) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [isslotAnimating]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);
   
            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);
     
            audioRef.current.volume = 0.1;
        }
    };

    const { alert, showAlert, hideAlert } = useAlert();
    const { prompt, showPrompt, hidePrompt } = usePrompt();

    useEffect(() => {
        gsap.fromTo(
            "#bankslotmachineview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        if (isAnimating === true) {
            gsap.to("#bankslotmachineview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    useEffect(() => {
        if (isbankcasinoviewanimating) {
            gsap.to("#bankslotmachineview", {
                opacity: 0,
                duration: 1.5,
                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/casino");
                },
            });
        }
    }, [isbankcasinoviewanimating]);

    useEffect(() => {
        setisbankcasinoviewanimating(false);
    }, [isbankcasinoviewanimating]);
    const images = [
        {
            srcpc: soldierspc,
            id: "1209572870657941524",
            srcmob: soldiersmob,
            name: "Soldiers",
        },
        {
            srcpc: hillbilliespc,
            id: "1209572850881532004",
            srcmob: hillbilliesmob,
            name: "Hillbillies",
        },
        {
            srcpc: suitspc,
            id: "1209572867453493248",
            srcmob: suitsmob,
            name: "The Suits",
        },
        {
            srcpc: mechanicspc,
            id: "1209572878366801970",
            srcmob: mechanicsmob,
            name: "The Mechanics",
        },
        {
            srcpc: runnerspc,
            id: "1209572863611248660",
            srcmob: runnersmob,
            name: "Runners",
        },
    ];
    const [selectedFaction, setselectedFaction] = useState(null);
    const [selectedImage, setSelectedImage] = useState(images[0]);
    function getImageSource() {
        if (isLargeScreen) {
            return runslot ? selectedImage?.srcpc : staticfaction;
        } else {
            return runslot ? selectedImage?.srcmob : staticmobfaction;
        }
    }

    const handleImageClick = async () => {
        const discordId = localStorage.getItem("discord");
        if (!discordId) {
            showAlert("Please login to select a faction", "Login Alert");
            return;
        }

        const randomIndex = Math.floor(Math.random() * images.length); // Get a random index
        const randomImage = images[randomIndex];
        setSelectedImage(randomImage); // Set the random image
        setselectedFaction(images?.[randomIndex]?.id);

        await handleProvideSelectedFactionToUser(
            images?.[randomIndex]?.id,
            images?.[randomIndex]?.name
        );
    };

    const handleProvideSelectedFactionToUser = async (
        factionRoleId,
        factionName
    ) => {
        try {
            showAlert("Please wait for a moment", "Loading...");
            const twitterUid = localStorage.getItem("twitter");
            if (!twitterUid) {
                hideAlert();
                showAlert("Please login to select a faction", "Login Alert");
                return;
            }
            const userRes = await axios.get(
                `https://themobserver.themobonbtc.com/getData/${twitterUid}`
            );
            const isUserJoinedDiscord = userRes?.data?.isFoundInServer;
            if (!isUserJoinedDiscord) {
                hideAlert();
                showAlert(
                    "Please join our discord server to select a faction",
                    "You have not joined our discord server yet"
                );
                return;
            }
            const userFaction = userRes?.data?.faction?.factionRoleId;
            if (userFaction) {
                hideAlert();
                const out = await showPrompt(
                    <p className="font-sans text-black">
                        You will lose{" "}
                        <strong className="font-bold">10 points</strong> if you
                        re-roll for a faction change do you wish to continue?
                    </p>,
                    "Faction Reassignment Penality"
                );
                if (!out) {
                    return;
                }
                const userScore = Object.values(
                    userRes?.data?.scoreObject || {}
                )?.reduce((acc, curr) => acc + curr, 0);
                if (userScore < 10) {
                    hidePrompt();
                    hideAlert();
                    showAlert(
                        "You do not have enough points to re-roll for a faction change",
                        `Insufficient Points Points (${userScore})`
                    );
                    return;
                }
            }
            hideAlert();
            const discordId = localStorage.getItem("discord");
            setrunslot(true);
            setTimeout(async () => {
                setrunslot(false);
                showAlert(
                    "Loading... Please wait for a moment",
                    "Allocating your Faciton..."
                );
                const res = await axios.post(
                    "https://themobserver.themobonbtc.com/setFaction",
                    {
                        discordId,
                        factionRoleId,
                    }
                );
      
                if (res?.data === "faction_set") {
                    showAlert(
                        `You have been assigned with ${factionName} Faction`,
                        "Faction Assigned"
                    );
                }
                if (res?.data === "faction_changed") {
                    showAlert(
                        `Your faction has been changed successfully to ${factionName} , with a penality of 10 points`,
                        "Faction Reassigned"
                    );
                }
            }, 15000);
        } catch (error) {
            console.error("error", error);
        }
    };

    return (
        <div
            ref={containerRef}
            className="relative z-10 bg-black  min-h-screen max-h-screen flex flex-col items-center mx-auto overflow-auto w-full  "
            id="bankslotmachineview"
        >
            <div className="relative max-h-screen">
                <img
                    src={getImageSource()}
                    className="h-screen w-screen object-fill"
                    alt="Background"
                />

                {/* runslot Faction-------------------------------------------------------------------------------- */}
                <div id="runslotDiv">
                    <div
                        className={` ${
                            runslot ? "" : "interactive-area"
                        } top-[70%] left-[31%] sm:top-[70%] sm:left-[35%] h-[80px] w-[160px] md:top-[55%] md:left-[37%] 2xl:top-[55%] 2xl:left-[45%] lg:top-[52%] lg:left-[42%] 2xl:h-[160px] 2xl:w-[280px]  lg:h-[160px] lg:w-[240px] sm:h-[100px] sm:w-[200px]  md:h-[120px] md:w-[220px]`}
                        onClick={handleImageClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                </div>
            </div>

            {/* banknavbar icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    setisbankcasinoviewanimating={setisbankcasinoviewanimating}
                    isbankcasinoviewanimating={isbankcasinoviewanimating}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isMusicPlayed={isMusicPlayed}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                />
            </div>
            <CustomAlert alert={alert} hideAlert={hideAlert} />
            <CustomPrompt prompt={prompt} hidePrompt={hidePrompt} />
        </div>
    );
}

export default BankSlotMachineView;
