import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import { SplitText } from "gsap/SplitText";
import "./bank.css";
import gsap from "gsap";
import bankview from "../../../../assets/images/casino_background.jpg";
import casinopagemob from "../../../../assets/images/casinopagemob.jpg";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import { useNavigate } from "react-router-dom";
function BankCasinoView({
    data,

    pageName,
    setisElementsHidden,
    isElementsHidden,
    handleHide,
    setPageName,
    setNavbarVisible,
    handleSubscribe,

    isLargeScreen,
    setIsLargeScreen,
    isMusicPlayed,
    isUserInteracted,
    audioRef,

    playMusic,
}) {
    const containerRef = useRef(null);
    const [whitelist, setwhitelist] = useState(false);
    const navigate = useNavigate();
    const [isWhiteListAnimating, setisWhiteListAnimating] = useState(false);
    const [isSlotMachineAnimating, setisSlotMachineAnimating] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (whitelist) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [whitelist]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);
        
            audioRef.current.volume = 0.1;
           ;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);
     
            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bankcasinoview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        if (isAnimating === true) {
            gsap.to("#bankcasinoview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    useEffect(() => {
        if (isWhiteListAnimating === true) {
            gsap.to("#bankcasinoview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/whitelist");
                },
            });
        }

        return () => {};
    }, [isWhiteListAnimating]);

    useEffect(() => {
        if (isSlotMachineAnimating === true) {
            gsap.to("#bankcasinoview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/slot");
                },
            });
        }

        return () => {};
    }, [isSlotMachineAnimating]);

    return (
        <div
            ref={containerRef}
            className="relative  z-10 min-h-screen max-h-screen flex flex-col items-center mx-auto overflow-hidden w-full  "
            id="bankcasinoview"
        >
            <div className="relative max-h-screen">
                {isLargeScreen ? (
                    <img
                        src={bankview}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={casinopagemob}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}
                {/* slotmachine Faction-------------------------------------------------------------------------------- */}
                <div id="slotMachineDiv">
                    <div
                        className="interactive-area  top-[35%] left-[47%]  h-[60px] w-[60px] md:top-[29%] md:left-[42%] lg:top-[21%] lg:left-[45%] xl:top-[20%] xl:left-[46%]  lg:h-[220px] lg:w-[220px]  xl:h-[280px] xl:w-[280px] md:h-[150px] md:w-[150px]"
                        onClick={() => setisSlotMachineAnimating(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                </div>{" "}
                {/* whitelist Faction-------------------------------------------------------------------------------- */}
                <div id="whitelistDiv">
                    <div
                        className="interactive-area  top-[50%] left-[47%] h-[60px] w-[60px] md:top-[54%] md:left-[42%] lg:top-[52%] lg:left-[42%]  xl:top-[52%] xl:left-[47%]  lg:h-[220px] lg:w-[220px]  md:h-[150px] md:w-[150px]  xl:h-[280px] xl:w-[280px]"
                        onClick={() => setisWhiteListAnimating(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                </div>
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    pageName={pageName}
                    setPageName={setPageName}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isMusicPlayed={isMusicPlayed}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                />
            </div>
        </div>
    );
}

export default BankCasinoView;
