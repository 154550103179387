import React from "react";
import questbg from "../../../../assets/icons/Quest.png";
import pointsbg from "../../../../assets/icons/Points.png";
import Modal from "@mui/material/Modal";
import questalone from "../../../../assets/images/questalone.png";
function BankQuestsCardAlone({
    setisquests,
    y,
    showAlert,
    handleTwitterFollow,
    myReferralCode,
    isEasterEggBox,
    isEasterEggPointsGiven,
    updateRefferal,
    setisEasterEggBox,
    setreferralData,
    isEasterEggLocationBox,
    isEasterEggLocationPointsGiven,
    referralData,
    isEasterEggAddressPointsGiven,
    setisEasterEggAddressBox,
    isEasterEggPostageBox,
    isEasterEggPostagePointsGiven,
    setisEasterEggPostageBox,
    setisEasterEggLocationBox,
    isEasterEggQuest19PointsGiven,
    isEasterEggQuest19Box,
    setisEasterEggQuest19Box,
    setisEasterEggQuest21Box,
    isEasterEggQuest21Box,
    isEasterEggQuest21PointsGiven,
}) {
    return (
        <div className="w-full relative   ">
            <div
                className={` ${
                    y?.isDone ? "cursor-default" : "cursor-pointer"
                } flex   `}
                onClick={() => {
                    if (y?.scoreTag === "easterEgg") {
                        if (!isEasterEggPointsGiven) {
                            setisEasterEggBox(true);
                            console.log("Easter Egg Clicked");
                        }

                        return;
                    }
                    if (y?.scoreTag === "easterEgg_address") {
                        if (!isEasterEggAddressPointsGiven) {
                            setisEasterEggAddressBox(true);
                            console.log("Easter Egg Address Clicked");
                        }

                        return;
                    }
                    if (y?.scoreTag === "easterEgg_postage") {
                        if (!isEasterEggPostagePointsGiven) {
                            setisEasterEggPostageBox(true);
                            console.log("Easter Egg Address Clicked");
                        }

                        return;
                    }
                    if (y?.scoreTag === "easterEgg_quest17") {
                        if (!isEasterEggLocationPointsGiven) {
                            setisEasterEggLocationBox(true);
                            console.log("Easter Egg Location Clicked");
                        }

                        return;
                    }
                    if (y?.scoreTag === "easterEgg_quest19") {
                        if (!isEasterEggQuest19PointsGiven) {
                            setisEasterEggQuest19Box(true);
                            console.log("Easter Egg Location Clicked");
                        }

                        return;
                    }
                    if (y?.scoreTag === "easterEgg_quest21") {
                        if (!isEasterEggQuest21PointsGiven) {
                            setisEasterEggQuest21Box(true);
                            console.log("Easter Egg Location Clicked");
                        }

                        return;
                    }
                    if (y?.link) {
                        setisquests(false);

                        if (y?.scoreTag === "joinedDiscord") {
                            window.open(y.link, "_blank");
                            return;
                        }

                        if (y?.scoreTag === "referral") {
                            if (myReferralCode === null) {
                                showAlert(
                                    "Please connect your twitter account first",
                                    "Alert"
                                );
                                return;
                            }
                            const referralLink = `${window.location.origin}/?referral=${myReferralCode}`;
                            navigator.clipboard.writeText(referralLink);
                            showAlert(
                                "Referral link copied to clipboard",
                                "Alert"
                            );
                            return;
                        }

                        handleTwitterFollow(y.link, y.scoreTag);
                    }
                }}
            >
                <div className="w-full  object-contain ">
                    {" "}
                    <img src={questalone} className="w-full   " alt="" />
                </div>

                <div className="absolute top-2 left-2 sm:top-4 sm:left-4 flex justify-center   items-center">
                    {/* <img
                        src={questbg}
                        className="w-[60px] lg:h-[20px] h-[12px] object-fill 2xl:w-[80px] "
                        alt=""
                    /> */}
                    <h5 className="h10 text-center text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                        QUEST #
                    </h5>
                    <h5 className="h10 text-center text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                        {" "}
                        {y?.id}
                    </h5>
                </div>
                <div className="absolute top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center w-full px-2">
                    <h5
                        className={`h10 text-center text-[10px] sm:text-[12px] md:text-[15px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]  ${
                            y?.id === 15 &&
                            "text-[8px] sm:text-[9px] md:text-[11px] lg:text-[10px] xl:text-[14px] 2xl:text-[14px]"
                        } flex-col font-medium`}
                    >
                        {y?.name} <br></br>
                        {y?.name2}
                    </h5>
                </div>
                <div className=" absolute top-2 right-2 sm:right-4 sm:top-4">
                    {y.isDone && (
                        <div>
                            <i class="fa-xl fa-solid fa-check-to-slot"></i>
                        </div>
                    )}
                </div>

                <div className="absolute gap-1 bottom-2 right-2 sm:bottom-4 sm:right-8 flex justify-center   items-center">
                    <h5 className="h10 text-center text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                        POINTS:
                    </h5>
                    <h5 className="h10 text-center text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                        {" "}
                        {y?.points}
                    </h5>
                </div>
                {(y?.id === 11 ||
                    y?.id === 12 ||
                    y?.id === 15 ||
                    y?.id === 17 ||
                    y?.id === 19 ||
                    y?.id === 21) && (
                    <div className="absolute gap-1 bottom-2 left-2 sm:bottom-4 sm:left-8 flex justify-center   items-center">
                        <h5 className="h10 text-center text-[10px] sm:text-[12px] md:text-[14px] lg:text-[16px] xl:text-[20px] font-bold">
                            Click to submit
                        </h5>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BankQuestsCardAlone;
