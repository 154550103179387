import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import newMainBg from "../../../../assets/images/newmain.png";
import bridgemobile from "../../../../assets/images/bridgemobile.png";
import bankmobilemain from "../../../../assets/images/bankmobilemain.png";
import mechanics from "../../../../assets/images/factions/mechanics.png";
import soldiers from "../../../../assets/images/factions/soldiers.png";
import closed from "../../../../assets/images/closed.png";
import runners from "../../../../assets/images/factions/runners.png";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import "react-h5-audio-player/lib/styles.css";
import smokinglogo from "../../../../assets/icons/smokinglogo.gif";
import logomob from "../../../../assets/icons/logomob.png";
import "./bank.css";
import gsap from "gsap";
import BankOpening from "../opening/BankOpening";
import { useNavigate } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
function Bank({
    data,

    isBankLoading,

    setisElementsHidden,
    isElementsHidden,
    handleHide,

    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,

    isLargeScreen,
    setIsLargeScreen,
    audioRef,

    playMusic,
    isPrompt,
    setisPrompt,
}) {
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const [isAnimating, setIsAnimating] = useState(false);

    const [isBridgeAnimating, setisBridgeAnimating] = useState(false);
    const [isSpeakEasyAnimating, setisSpeakEasyAnimating] = useState(false);
    const [isbankcityAnimating, setisbankcityAnimating] = useState(false);
    const [yellowManchildModalOpen, setyellowManchildModalOpen] =
        useState(false);
    const [runnersManchildModalOpen, setrunnersManchildModalOpen] =
        useState(false);
    const [policeManModalOpen, setpoliceManModalOpen] = useState(false);
    const [mechanicManModalOpen, setmechanicManModalOpen] = useState(false);
    const [soldiersManModalOpen, setsoldiersManModalOpen] = useState(false);
    const [deadBodyModalOpen, setdeadBodyModalOpen] = useState(false);
    const [isDeadBodyAnimating, setisDeadBodyAnimating] = useState(false);
    const [yellowMan, setYellowMan] = useState(false);

    const [deadBody, setdeadBody] = useState(false);
    const [policeMan, setpoliceMan] = useState(false);
    const [mechanicMan, setmechanicMan] = useState(false);
    const [soldiersMan, setsoldiersMan] = useState(false);
    const [runnersMan, setrunnersMan] = useState(false);
    const [bankView, setbankView] = useState(false);
    const [speakEasy, setspeakEasy] = useState(false);
        const [bankcity, setbankcity] = useState(false);
    const [active, setActive] = useState(false);

    useEffect(() => {
        gsap.fromTo(
            "#bank",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        if (isBankLoading === false) {
            gsap.fromTo(
                "#bank",
                {
                    opacity: 0,
                    duration: 1.5,

                    ease: "Expo.easeInOut",
                },
                {
                    duration: 1.5,
                    opacity: 1,
                    ease: "Expo.easeInOut",
                }
            );
        }

        return () => {};
    }, [isBankLoading]);

    useEffect(() => {
        if (
            yellowMan ||
            runnersMan ||
            policeMan ||
            soldiersMan ||
            mechanicMan ||
            deadBody
        ) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [yellowMan, runnersMan, policeMan, soldiersMan, mechanicMan, deadBody]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);

            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {}, [isBankLoading]);
    useEffect(() => {
        gsap.set("#bank", { scale: 1, opacity: 1 });

        // Your existing animation trigger
        if (isAnimating === true) {
            gsap.to("#bank", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/bank");
                },
            });
        }

        return () => {
            gsap.set("#bank", { scale: 1, opacity: 1 });
        };
    }, [isAnimating]);

    useEffect(() => {
        gsap.set("#bank", { scale: 1, opacity: 1 });

        // Your existing animation trigger
        if (isSpeakEasyAnimating === true) {
            gsap.to("#bank", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/speakeasy");
                },
            });
        }

        return () => {
            gsap.set("#bank", { scale: 1, opacity: 1 });
        };
    }, [isSpeakEasyAnimating]);

        useEffect(() => {
            gsap.set("#bank", { scale: 1, opacity: 1 });

            // Your existing animation trigger
            if (isbankcityAnimating === true) {
                gsap.to("#bank", {
                    opacity: 0,
                    duration: 1.5,

                    ease: "Expo.easeInOut",
                    onComplete: () => {
                        navigate("/city");
                    },
                });
            }

            return () => {
                gsap.set("#bank", { scale: 1, opacity: 1 });
            };
        }, [isbankcityAnimating]);

    useEffect(() => {
        gsap.set("#bank", { scale: 1, opacity: 1 });

        // Your existing animation trigger
        if (isBridgeAnimating === true) {
            gsap.to("#bank", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/bridge");
                },
            });
        }

        return () => {
            gsap.set("#bank", { scale: 1, opacity: 1 });
        };
    }, [isBridgeAnimating]);

    useEffect(() => {
        const hasShownModal = sessionStorage.getItem("hasShownModal");

        if (!isLargeScreen && !hasShownModal) {
            setisPrompt(true);
            sessionStorage.setItem("hasShownModal", "true");
        }
    }, [isLargeScreen]);

    useEffect(() => {
        gsap.set("#bank", { scale: 1, opacity: 1 });

        // Your existing animation trigger
        if (isDeadBodyAnimating === true) {
            gsap.to("#bank", {
                scale: 4,
                duration: 1.5,
                y: "-300px",
                x: "0px",
                ease: "Expo.easeInOut",
            });
        }

        return () => {
            gsap.set("#bank", { scale: 1, opacity: 1 });
        };
    }, [isDeadBodyAnimating]);

    if (isBankLoading) {
        return <BankOpening />;
    }

    return (
        <div
            ref={containerRef}
            className="relative min-h-screen flex flex-col items-center mx-auto overflow-hidden md:overflow-auto w-full   "
            id="bank"
        >
            {isPrompt && !isLargeScreen && (
                <Modal open={!isLargeScreen} onClose={() => setisPrompt(false)}>
                    <div className=" bg-[#EF7335] h-screen w-screen flex flex-col gap-10 relative ">
                        <div className="w-full static  top-0 z-20 ">
                            <BankNavbar
                                setisElementsHidden={setisElementsHidden}
                                handleHide={handleHide}
                                isElementsHidden={isElementsHidden}
                                data={data}
                                setNavbarVisible={setNavbarVisible}
                                handleSubscribe={handleSubscribe}
                                playMusic={playMusic}
                                isMusicPlayed={isMusicPlayed}
                                isLargeScreen={isLargeScreen}
                                setIsLargeScreen={setIsLargeScreen}
                            />
                        </div>
                        <img
                            src={logomob}
                            className="w-[100px] mx-auto flex justify-center"
                            alt="logomob"
                        />
                        <p className="text-lg tracking-[0px]  font-bold text-center text-black">
                            OUR WEBSITE IS CURRENTLY <br></br> BEING
                            INVESTIGATED BY THE POLICE.
                        </p>
                        <p className="text-lg tracking-[0px]  font-bold text-center text-black">
                            FOR A BETTER USER EXPERIENCE, WE HIGHLY <br></br>{" "}
                            RECOMMEND GOING ONTO DESKTOP INSTEAD.
                        </p>
                        <p className="text-lg tracking-[0px]  font-bold text-center text-black">
                            ALWAYS REMEMBER: <br></br>THE MOB IS WATCHING YOU.
                        </p>
                        <i
                            onClick={() => setisPrompt(false)}
                            className="fa-solid fa-xmark  mx-auto text-[45px] text-black"
                        ></i>
                    </div>
                </Modal>
            )}

            <div className=" relative ">
                {isLargeScreen ? (
                    <img
                        src={newMainBg}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={bankmobilemain}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}

                {/* YellowMan Faction-------------------------------------------------------------------------------- */}
                <div id="yellowManDiv">
                    <div
                        onClick={() => setyellowManchildModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[61%] left-[15%] h-[40px] w-[40px] md:top-[59%] md:left-[12%] lg:top-[79%] lg:left-[18%] lg:h-[180px] lg:w-[180px]  md:h-[150px] md:w-[150px]  "
                    ></div>{" "}
                    {yellowManchildModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={yellowManchildModalOpen}
                            onClose={() => setyellowManchildModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setYellowMan(true);
                                    setyellowManchildModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%] outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {yellowMan && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={yellowMan === true}
                            onClose={() => setYellowMan(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Fade className="text-transparent pb-14 sm:px-14 sm:py-14 w-full  xl:w-1/2 xl:px-24 border-none rounded-lg outline-none background-paper">
                                <p className="text-transparent px-2  py-2 cursiveHandwriting ">
                                    <p className=" ml-4 text-center italic text-transparent mb-2  font-bold">
                                        {" "}
                                        “I was never in control. I just got us
                                        moving and let go of the wheel."
                                    </p>
                                    I’m the Boss. I’m faceless, cloaked in
                                    shadow. I don’t ask, I order. The Mob is
                                    mine and no one else's. I’m the man behind
                                    the curtain, the thing under the bed. I’m a
                                    myth, a legend, a red herring. I’m the lie
                                    we told the enemy. I don’t fucking exist.
                                    We’re decentralized. We’re thousands strong,
                                    united by purpose. One of us falls and two
                                    rise. We’re family, bound tighter than
                                    blood. We’re not going anywhere. No Bosses,
                                    we’re all our own kings.
                                </p>
                            </Fade>
                        </Modal>
                    )}
                </div>

                {/* Mechanic Faction-------------------------------------------------------------------------------- */}
                <div id="mechanicFactionDiv">
                    <div
                        onClick={() => setmechanicManModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[51%] left-[75%] h-[40px] w-[40px] md:top-[48%] md:left-[74%] lg:top-[50%] lg:left-[78%]  lg:h-[160px] lg:w-[160px]  md:h-[100px] md:w-[100px] "
                    ></div>{" "}
                    {mechanicManModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={mechanicManModalOpen}
                            onClose={() => setmechanicManModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setmechanicMan(true);
                                    setmechanicManModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {mechanicMan && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={mechanicMan === true}
                            onClose={() => setmechanicMan(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className="text-transparent 2xl:px-24 pb-14 sm:px-14 sm:py-14 w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                <Fade>
                                    {" "}
                                    <img
                                        src={mechanics}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]    sm:h-[200px] lg:h-[300px]  mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade>
                                <p className="   cursiveHandwriting text-transparent ">
                                    <p className="text-center italic text-transparent mb-2 font-bold">
                                        {" "}
                                        “I need it fast enough that nobody can
                                        catch me, powerful enough to drive
                                        through a roadblock, andsmooth enough
                                        that the moonshine doesn’t shatter when
                                        I hit a pothole. It needs to look good,
                                        too."
                                    </p>
                                    Get oil on your clothes, calluses on your
                                    hands. Work at night, daytime’s too risky.
                                    Know cars like a fish knows water. When one
                                    rolls in, replace the tires, change the oil,
                                    and rebuild the engine. Give it fresh paint,
                                    buff it until it shines. Job well done, it’s
                                    back on the streets like nothing happened.
                                    Guns need maintenance or they’ll misfire.
                                    Get them shooting straight. You fix the
                                    broken, bring machines back to life. You’re
                                    a Mechanic.
                                </p>
                            </div>
                        </Modal>
                    )}{" "}
                </div>

                {/* runners Faction-------------------------------------------------------------------------------- */}
                <div id="runnersFactionDiv">
                    <div
                        onClick={() => setrunnersManchildModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area  top-[48%] left-[34%] h-[40px] w-[40px] md:top-[50%] md:left-[34%]  lg:top-[50%] lg:left-[35%] lg:h-[160px] lg:w-[160px]  md:h-[100px] md:w-[100px] "
                    ></div>{" "}
                    {runnersManchildModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={runnersManchildModalOpen}
                            onClose={() => setrunnersManchildModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setrunnersMan(true);
                                    setrunnersManchildModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%] outline-none border-none cursor-pointer "
                                alt=""
                            />
                        </Modal>
                    )}
                    {runnersMan && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={runnersMan === true}
                            onClose={() => setrunnersMan(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className="text-transparent 2xl:px-24 pb-14 sm:px-14 sm:py-14 w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                <Fade>
                                    {" "}
                                    <img
                                        src={runners}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]    sm:h-[200px] lg:h-[300px]  mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade>
                                <p className="text-transparent px-3 py-2 cursiveHandwriting ">
                                    <p className="text-center italic text-transparent mb-2 font-bold ">
                                        {" "}
                                        “It’s fine from a distance, step a
                                        little closer and it starts to smell.”
                                    </p>
                                    They watch, inspect, spy upon, direct,
                                    number, regulat, enroll, indoctrinate,
                                    control, check, estimate, value, censure,
                                    command. We are noted, registered, counted,
                                    taxed, stamped, measured, numbered,
                                    assessed, licensed, authorized, admonished,
                                    prevented, forbidden, reformed, corrected,
                                    punished, drilled, exploited, extorted,
                                    squeezed, hoaxed, robbed, repressed, fined,
                                    vilified, harassed, hunted down, abused,
                                    clubbed, disarmed, bound, choked,
                                    imprisoned, judged, condemned, shot,
                                    deported, sacrificed, sold, betrayed,
                                    mocked, ridiculed, derided, outraged,
                                    dishonored.
                                    <br></br> <br></br>
                                    No more.
                                </p>
                            </div>
                        </Modal>
                    )}{" "}
                </div>

                {/* deadbody Faction-------------------------------------------------------------------------------- */}
                <div id="deadbodyDiv">
                    <div
                        onClick={() => setdeadBodyModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className=" interactive-area  top-[51%] left-[48%] sm:top-[51%] sm:left-[50%] h-[40px] w-[40px] md:top-[46%] md:left-[50%] lg:top-[56%] lg:left-[49%]  lg:h-[180px] lg:w-[180px]  md:h-[100px] md:w-[100px] "
                    ></div>
                    {deadBodyModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={deadBodyModalOpen}
                            onClose={() => setdeadBodyModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setdeadBody(true);
                                    setdeadBodyModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%] outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {deadBody && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={deadBody === true}
                            onClose={() => setdeadBody(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent 2xl:px-24 pb-14 sm:px-14 sm:py-14 w-full  xl:min-w-1/2 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setsoldiersMan(false)}
                                ></i> */}
                                {/* <Fade>
                                    {" "}
                                    <img
                                        src={soldiers}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]   sm:h-[200px] lg:h-[300px]  mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade> */}
                                <p className="   cursiveHandwriting text-transparent ">
                                    <p className="text-center italic text-transparent mb-2 font-bold">
                                        {" "}
                                        “Our oath isn’t for show. Don’t take it
                                        lightly.”
                                    </p>
                                    Loyalty’s easy to promise, but it’s hard to
                                    keep. <br></br> <br></br> He was a Suit: The
                                    Inner Circle, the best of us, the role
                                    model. <br></br> <br></br> He took the oath.
                                    He broke it. <br></br> <br></br>Now he’s
                                    nothing but meat, spilling blood onto
                                    asphalt. <br></br>
                                    <br></br> Pitiful.
                                </p>
                            </div>
                        </Modal>
                    )}{" "}
                </div>

                {/* soldiers Faction-------------------------------------------------------------------------------- */}
                <div id="soldiersFactionDiv">
                    <div
                        onClick={() => setsoldiersManModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className=" interactive-area  top-[47%] left-[0%] h-[40px] w-[40px] md:top-[42%] md:left-[1%] lg:top-[39%] lg:left-[1%]  lg:h-[180px] lg:w-[180px]  md:h-[100px] md:w-[100px]"
                    ></div>
                    {soldiersManModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={soldiersManModalOpen}
                            onClose={() => setsoldiersManModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setsoldiersMan(true);
                                    setsoldiersManModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%] outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {soldiersMan && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={soldiersMan === true}
                            onClose={() => setsoldiersMan(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent 2xl:px-24 pb-14 sm:px-14 sm:py-14 w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setsoldiersMan(false)}
                                ></i> */}
                                <Fade>
                                    {" "}
                                    <img
                                        src={soldiers}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]   sm:h-[200px] lg:h-[300px]  mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade>
                                <p className="   cursiveHandwriting text-transparent ">
                                    <p className="text-center italic text-transparent mb-2 font-bold">
                                        {" "}
                                        “We forget who we are until it's just us
                                        and a stranger in a dark alley. Just
                                        like that, we’re at war."
                                    </p>
                                    Grow massive, scar your knuckles, then
                                    instill discipline. Stand behind the dealer,
                                    intimidate the gamblers so they don’t cheat.
                                    Someone’s out of line, knock on the door and
                                    set them straight. Find a thief, kick down
                                    the door and break their fingers. It’s
                                    violent work. You’re a violent man. Enforce
                                    the code. Oathbreakers don’t deserve
                                    warnings, give them nothing but a bullet and
                                    a shallow grave. You’re a Soldier.
                                </p>
                            </div>
                        </Modal>
                    )}{" "}
                </div>

                {/* police Faction-------------------------------------------------------------------------------- */}
                <div id="policeFactionDiv">
                    <div
                        onClick={() => setpoliceManModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[53%] left-[20%] h-[40px] w-[40px] md:top-[50%] md:left-[23%] lg:top-[54%] lg:left-[25%] lg:h-[160px] lg:w-[160px]   md:h-[100px] md:w-[100px] "
                    ></div>
                    {policeManModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={policeManModalOpen}
                            onClose={() => setpoliceManModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setpoliceMan(true);
                                    setpoliceManModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%] outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {policeMan && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={policeMan === true}
                            onClose={() => setpoliceMan(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent pb-14 sm:px-14 sm:py-14 xl:px-24 w-full  xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setpoliceMan(false)}
                                ></i> */}
                                <p className="text-transparent px-3 py-2 cursiveHandwriting ">
                                    <p className="text-center italic text-transparent mb-2 font-bold ">
                                        {" "}
                                        “Authority can’t be borrowed. It has to
                                        be earned.”
                                    </p>
                                    They’re cattle, herd animals that somebody
                                    put in uniform. If you wait by the station
                                    for their shift to end, you’ll see how they
                                    act without badges or backup. All that
                                    swaggering, all that bullying: Poof. It
                                    vanishes. They’re scared when they’re alone.
                                    They feel the wolves circling, and their
                                    primal instincts kick in to remind them that
                                    they’re prey. The only difference between
                                    cops and cows is that cops are too fucking
                                    to stupid listen.
                                </p>
                            </div>
                        </Modal>
                    )}{" "}
                </div>

                {/* speakeasy Faction-------------------------------------------------------------------------------- */}

                <div id="speakEasyDiv">
                    {!speakEasy && (
                        <div
                            onClick={() => setisSpeakEasyAnimating(true)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="interactive-area top-[40%] left-[45%] h-[40px] w-[40px] md:top-[36%] md:left-[43%] lg:top-[22%] lg:left-[47%]  lg:h-[200px] lg:w-[200px]   md:h-[120px] md:w-[120px]  "
                        ></div>
                    )}
                </div>

                {/* bankcity Faction-------------------------------------------------------------------------------- */}

                <div id="bankCityDiv">
                    {!bankcity && (
                        <div
                            onClick={() => setisbankcityAnimating(true)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="interactive-area top-[60%] left-[65%] h-[40px] w-[40px] md:top-[60%] md:left-[68%] lg:top-[72%] lg:left-[67%]  lg:h-[200px] lg:w-[200px]   md:h-[120px] md:w-[120px] "
                        ></div>
                    )}
                </div>

                {/* bankView Faction-------------------------------------------------------------------------------- */}

                <div id="bankViewDiv">
                    {!bankView && (
                        <div
                            onClick={() => setIsAnimating(true)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="interactive-area top-[43%] left-[25%] h-[40px] w-[40px] md:top-[37%] md:left-[23%] lg:top-[26%] lg:left-[29%]  lg:h-[220px] lg:w-[220px]   md:h-[150px] md:w-[150px]  "
                        ></div>
                    )}
                </div>

                {/* bridgeView Faction-------------------------------------------------------------------------------- */}

                <div id="bridgeViewDiv">
                    <div
                        onClick={() => setisBridgeAnimating(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[41%] left-[65%] h-[40px] w-[40px] md:top-[37%] md:left-[63%] lg:top-[24%] lg:left-[64%]  lg:h-[220px] lg:w-[220px]  md:h-[150px] md:w-[150px]  "
                    ></div>
                </div>
            </div>
            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isMusicPlayed={isMusicPlayed}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                />
            </div>
        </div>
    );
}

export default Bank;
