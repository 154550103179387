import React, { useRef, useEffect, useState } from "react";
import BankNavbar from "./BankNavbar";
import Modal from "@mui/material/Modal";
import { SplitText } from "gsap/SplitText";
import suitsbg from "../../../../assets/images/factions/suits.png";
import closed from "../../../../assets/images/closed.png";
import "./bank.css";
import deadbody from "../../../../assets/images/deadbody.jfif";
import gsap from "gsap";
import bankview from "../../../../assets/images/bankbg.png";
import bankviewmobile from "../../../../assets/images/bank_mobile.png";
import posterpic from "../../../../assets/images/posterpic.jfif";
import bankmobile from "../../../../assets/images/bankmobile.png";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
function BankView({
    data,
    handleBank,
    pageName,
    setisElementsHidden,
    isElementsHidden,
    handleHide,
    setPageName,
    setNavbarVisible,
    handleSubscribe,
    isMusicPlayed,
    isUserInteracted,
    isLargeScreen,
    setIsLargeScreen,
    audioRef,
    playMusic,
}) {
    const containerRef = useRef(null);
    const [newspaper, setnewspaper] = useState(false);
    const [posters, setposters] = useState(false);
    const [suits, setsuits] = useState(false);

    const [newspaperModalOpen, setnewspaperModalOpen] = useState(false);
    const [postersModalOpen, setpostersModalOpen] = useState(false);
    const [suitsModalOpen, setsuitsModalOpen] = useState(false);

    const [isAnimating, setIsAnimating] = useState(false);
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (newspaper || posters || suits) {
            setTimeout(() => {
                gsap.registerPlugin(SplitText);
                const innovationpara = new SplitText(".cursiveHandwriting", {
                    type: "words",
                });

                gsap.fromTo(
                    innovationpara.words,
                    {
                        opacity: 0,
                        color: "transparent",
                    },
                    {
                        duration: 1,
                        opacity: 1,
                        color: "black",
                        ease: "none",
                        stagger: {
                            each: 0.05,
                            from: "start",
                        },
                    }
                );

                return () => innovationpara.revert();
            }, 0);
        }
    }, [newspaper, posters, suits]);

    const handleMouseEnter = () => {
        if (isUserInteracted) {
            setActive(true);

            audioRef.current.volume = 0.1;
        }
    };

    const handleMouseLeave = () => {
        if (isUserInteracted) {
            setActive(false);

            audioRef.current.volume = 0.1;
        }
    };

    useEffect(() => {
        gsap.fromTo(
            "#bankview",
            {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
            },
            {
                duration: 1.5,
                opacity: 1,
                ease: "Expo.easeInOut",
            }
        );

        return () => {};
    }, []);

    useEffect(() => {
        // Your existing animation trigger
        if (isAnimating === true) {
            gsap.to("#bankview", {
                opacity: 0,
                duration: 1.5,

                ease: "Expo.easeInOut",
                onComplete: () => {
                    navigate("/");
                },
            });
        }

        return () => {};
    }, [isAnimating]);

    return (
        <div
            ref={containerRef}
            className="relative min-h-screen flex flex-col items-center mx-auto overflow-hidden md:overflow-auto w-full  "
            id="bankview"
        >
            <div className="relative max-h-screen">
                {isLargeScreen ? (
                    <img
                        src={bankview}
                        className="h-screen w-screen object-fill"
                        alt="Background"
                    />
                ) : (
                    <img
                        src={bankmobile}
                        className="h-screen w-screen object-fill "
                        alt="Background"
                    />
                )}
                {/* suits Faction-------------------------------------------------------------------------------- */}
                <div id="suitsDiv">
                    <div
                        onClick={() => setsuitsModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[68%] right-[1%] h-[60px] w-[60px] md:top-[58%] md:right-[1%] lg:top-[58%] lg:left-[82%]  lg:h-[180px] lg:w-[180px]  md:h-[120px] md:w-[120px] "
                    ></div>{" "}
                    {suitsModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={suitsModalOpen}
                            onClose={() => setsuitsModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setsuits(true);
                                    setsuitsModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {suits && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={suits === true}
                            onClose={() => setsuits(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent pb-14 sm:px-14 sm:py-14 w-full xl:px-24 xl:w-1/2 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setsuits(false)}
                                ></i>{" "} */}
                                <Fade>
                                    {" "}
                                    <img
                                        src={suitsbg}
                                        className="sm:w-[200px] lg:w-[300px] w-[150px] h-[150px]   sm:h-[200px] lg:h-[300px] mx-auto flex justify-center mb-4"
                                        alt=""
                                    />{" "}
                                </Fade>
                                <p className="   cursiveHandwriting text-transparent ">
                                    <p className="text-center italic text-transparent mb-2 font-bold">
                                        {" "}
                                        “I dress to show people that I’ve got
                                        everything they want. It helps with
                                        recruiting."
                                    </p>
                                    Wear bespoke because class is part of the
                                    job. See the big picture. Demand respect,
                                    but give it right back. Be kind to the
                                    family and cruel to the enemy. Stay true to
                                    the mission. You’re the inner circle, right
                                    the ship if it drifts off course. Be the
                                    architect. Grow the business and make more
                                    money. Be the exemplar. Recruit new members,
                                    make them take the oath, then show them how
                                    to act. You’re a Suit.
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* posters Faction-------------------------------------------------------------------------------- */}
                <div id="postersDiv">
                    <div
                        onClick={() => setpostersModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="interactive-area top-[70%] left-[25%] h-[60px] w-[60px] md:left-[22%] md:top-[64%] lg:top-[64%] lg:left-[27%]  lg:h-[120px] lg:w-[120px]  md:h-[120px] md:w-[120px]"
                    ></div>{" "}
                    {postersModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={postersModalOpen}
                            onClose={() => setpostersModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setposters(true);
                                    setpostersModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {posters && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center"
                            open={posters === true}
                            onClose={() => setposters(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent px-4  py-24 md:pb-24  sm:py-14 w-[90%] lg:px-14 xl:w-[70%] border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setposters(false)}
                                ></i>{" "} */}
                                <img
                                    src={posterpic}
                                    className="sm:w-[100px] lg:w-[160px]   w-[80px] mb-3  mx-auto flex justify-center"
                                    alt=""
                                />{" "}
                                <p className="text-transparent  cursiveHandwriting  text-[13px]">
                                    <p className=" italic text-transparent mb-2 font-bold  text-center">
                                        {" "}
                                        “I won’t obey the laws of tyrants.”
                                    </p>
                                    <ul className="text-transparent">
                                        <li className="text-transparent">
                                            {" "}
                                            “Who’s this dickhead?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Our new mayor.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “I’ve never seen this guy in my
                                            life, there’s no way he won the
                                            vote.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “He ran uncontested.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “I thought Cliff was going for
                                            reelection.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Did you not see the news?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “I don’t read that bullshit. What
                                            happened?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Cliff’s house burned down. Nobody
                                            made it out.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Jesus. Did they say what caused
                                            it?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Somebody left the stove on.
                                            Probably their little daughter, she
                                            was too young to know any better.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “That’s awful.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Cliff was a good man, old school.
                                            There aren’t many like him left in
                                            politics.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “How the hell did the daughter turn
                                            the stove on and nobody noticed?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “The election must’ve had them
                                            distracted. ”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Must’ve. But isn’t it a little
                                            strange?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “What?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “The whole thing. Everybody knew
                                            Cliff wasn’t losing that race. Then
                                            his house burns down and this guy
                                            who nobody’s heard of ends up mayor?
                                            Why was he even running in the first
                                            place?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Some people just want their name on
                                            the ballot. It happens all the
                                            time.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Then why wasn’t anybody else’s name
                                            on the goddamn ballot?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “I didn’t think about that.”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “You see what I’m saying?”
                                        </li>
                                        <li className="text-transparent">
                                            {" "}
                                            “Yeah, it’s strange.”
                                        </li>{" "}
                                        <li className="text-transparent">
                                            {" "}
                                            “Really fucking strange.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>

                {/* newspaper Faction-------------------------------------------------------------------------------- */}
                <div id="newspaperDiv">
                    <div
                        className="interactive-area top-[67%] left-[2%] h-[60px] w-[60px] md:top-[58%] md:left-[5%] lg:top-[60%] lg:left-[5%]  lg:h-[120px] lg:w-[120px]  md:h-[120px] md:w-[120px]"
                        onClick={() => setnewspaperModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>{" "}
                    {newspaperModalOpen && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={newspaperModalOpen}
                            onClose={() => setnewspaperModalOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <img
                                onClick={() => {
                                    setnewspaper(true);
                                    setnewspaperModalOpen(false);
                                }}
                                src={closed}
                                className="xl:w-[40%] lg:w-[50%]  sm:w-[400px] md:w-[60%] w-[70%]  outline-none border-none cursor-pointer"
                                alt=""
                            />
                        </Modal>
                    )}
                    {newspaper && (
                        <Modal
                            className=" w-fit border-none  mx-auto outline-none justify-center  "
                            open={newspaper === true}
                            onClose={() => setnewspaper(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    backdropFilter: "blur(5px)",
                                },
                            }}
                        >
                            <div className=" text-transparent sm::py-14 w-full md:w-1/2 p-10 md:px-14 lg:px-24 border-none rounded-lg outline-none background-paper">
                                {" "}
                                {/* <i
                                    className="fa-solid fa-x text-red-700 z-20 cursor-pointer flex justify-end hover:text-red-500 duration-200 ease-in"
                                    onClick={() => setnewspaper(false)}
                                ></i>{" "} */}
                                <p className="text-transparent cursiveHandwriting px-3 py-2 ">
                                    <p className="text-center italic font-bold text-transparent mb-2 ">
                                        {" "}
                                        “We know exactly who they are and how
                                        they think. They can’t even comprehend
                                        us.”
                                    </p>
                                    Slick back your hair, show off your tattoos.
                                    Walk dogs, wait tables, deliver the papers
                                    and add some truth to their propaganda. Stay
                                    subtle, always use ciphers. Stay invisible,
                                    just another kid working. Always watch,
                                    always listen. Take the information you
                                    gather and piece it together. The enemy’s
                                    planning something, go spy. They can’t catch
                                    you. You’re a Runner.
                                </p>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>

            {/* social icons-------------------------------------------------------------------------------- */}
            <div className="w-full absolute md:fixed top-0 z-20 ">
                <BankNavbar
                    setisElementsHidden={setisElementsHidden}
                    handleHide={handleHide}
                    isElementsHidden={isElementsHidden}
                    data={data}
                    setNavbarVisible={setNavbarVisible}
                    pageName={pageName}
                    setPageName={setPageName}
                    handleSubscribe={handleSubscribe}
                    playMusic={playMusic}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    isLargeScreen={isLargeScreen}
                    setIsLargeScreen={setIsLargeScreen}
                    isMusicPlayed={isMusicPlayed}
                />
            </div>
        </div>
    );
}

export default BankView;
