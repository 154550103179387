import React, { useEffect, useState } from "react";
import gsap from "gsap";
import "../bank/bank.css";
import { easeInOut } from "framer-motion";

import logomoborange from "../../../../assets/icons/logomoborange.png";
import { Rotate } from "react-awesome-reveal";
function BankOpening() {
    const [countDown, setCountDown] = useState(0);
    useEffect(() => {
        // Animate the yellowFill to expand from 0 to full width
        gsap.fromTo(
            "#yellowFill",
            { width: "0%", backgroundColor: "#bf5331" },
            {
                width: "100%",
                delay: 0.5,
                duration: 0.5,
 backgroundColor: "#e7dec2",
                ease: "power1.inOut",
                onComplete: () => {
                    const stripsContainer = document.createElement("div");
                    stripsContainer.style.position = "absolute";
                    stripsContainer.style.width = "100%";
                    stripsContainer.style.top = "0";

                    stripsContainer.style.height = "60%"; // Adjust height to control the covered area
                    stripsContainer.style.display = "flex";
                    stripsContainer.style.flexDirection = "column";
                    document
                        .getElementById("yellowFill")
                        .appendChild(stripsContainer);

                    const totalStrips = 10;
                    let initialHeight = 8; // Starting height percentage for the first strip
                    let heightDecrement = 0.5; // Decrement factor for each subsequent strip
                    for (let i = 0; i < totalStrips; i++) {
                        let strip = document.createElement("div");
                        strip.style.background = "black";
                        strip.style.width = "100%";
                        // Dynamically adjust height and bottom margin
                        strip.style.height = `${
                            initialHeight - i * heightDecrement
                        }%`;
                        strip.style.marginBottom = `${(totalStrips - i) * 2}px`; // Decreasing margin
                        strip.style.opacity = "0";
                        stripsContainer.appendChild(strip);

                        // Animate each strip to fade in
                        gsap.to(strip, {
                            opacity: 1,
                            duration: 0.2,
                            delay: i * 0.05,
                            onComplete: () => {
                                gsap.to("#yellowFill", {
                                    y: "100%",
                                    opacity: 0,
                                    overflowY: "hidden",
                                });
                            },
                        });
                    }
                },
            }
        );
    }, []);

    useEffect(() => {
        // Calculate the interval to use (2 seconds total, 100 increments)
        const intervalDuration = 1000 / 100;

        // Set up the interval
        const interval = setInterval(() => {
            setCountDown((prevCountDown) => {
                if (prevCountDown >= 100) {
                    clearInterval(interval); // Stop the interval when reaching 100
                    return 100;
                }
                return prevCountDown + 1; // Increment the counter
            });
        }, intervalDuration);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        gsap.to(".countdown", {
            opacity: 0,
            ease: easeInOut,
            delay: 1,
        });
    }, []);

    return (
        <div
            className="overflow-hidden h-screen w-full layer max-h-screen min-h-screen md:max-w-[100%] mx-auto max-w-full align-middle flex items-center justify-center content bg-black"
            id="opening"
        >
            <div className="w-full z-40  mx-auto flex flex-col">
                <h1 className="text-center countdown text-white">
                    {countDown}%
                </h1>
                <Rotate>
                    <img
                        src={logomoborange}
                        className="w-[100px]  countdown md:w-[200px] mx-auto flex justify-center mt-5"
                        alt=""
                    />
                </Rotate>
            </div>
            <div
                className="overflow-hidden max-h-screen"
                id="yellowFill"
                style={{
                    height: "100%",
                    backgroundColor: "#e7dec2",
                    position: "absolute",
                    left: 0,
                    top: 0,
                }}
            ></div>
        </div>
    );
}

export default BankOpening;
