import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../../redux/slices/themeSlice";

import gsap from "gsap";

function Navbar({ data, setPageName }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mode } = useSelector((state) => state.theme);
    const [isScrolled, setIsScrolled] = useState(false);
    const menuRef = useRef();
    const navLinks = data.navLinks;




    useEffect(() => {
        gsap.set(menuRef.current, { y: "-100%" });

    }, []);

    const handleMenuOpen = () => {
        setPageName("mobileNavbar");
    };



    const toggleDarkOrLight = () => {
        dispatch(toggleTheme());
    };
    const scrollToElement = (id) => {
        const element = document.getElementById(id);

        if (element) {
            gsap.to(window, {
                scrollTo: {
                    y: element.offsetTop,
                    offsetY: 100, // adjust this value as needed
                },
                ease: "power2.inOut",
            });
        }
    };

    return (
        <div
            className={` items-center align-middle w-full md:w-[90%] max-w-[80%] mx-auto rounded-[40px] 
     
            
            ${mode === "dark" ? "navbarBase" : "bg-transparent"}`}
        >
            <div className="flex justify-between items-center  md:px-24 px-6">
                <Link
                    to="/"
                    className="items-center justify-center align-middle"
                >
                    <div className="flex cursor-pointer ">
                        <h1 className="text-lg">THE MOB</h1>
                    </div>
                </Link>

                <div className="">
                    <i
                        className="fa-solid fa-bars cursor-pointer bg-black px-1 py-3 items-center text-white fa-lg"
                        onClick={handleMenuOpen}
                    ></i>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
