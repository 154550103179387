import { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import halls from "../src/assets/song/halls.mp3";
import NotFound from "./pages/shared/notfound/NotFound";
import LoadingBar from "react-top-loading-bar";
import {
    Navigate,
    Route,
    Routes,
    BrowserRouter as Router,
    Outlet,
} from "react-router-dom";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/SplitText";
import Home from "./pages/home/Home";
import Navbar from "./pages/shared/navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "./redux/slices/themeSlice";

import data from "../src/data.json";
import Bank from "./pages/home/components/bank/Bank";
import BankOpening from "./pages/home/components/opening/BankOpening";

import BridgeView from "./pages/home/components/bank/BridgeView";
import BankView from "./pages/home/components/bank/BankView";
import BankCasinoView from "./pages/home/components/bank/BankCasinoView";
import BankWhitelistView from "./pages/home/components/bank/BankWhitelistView";
import BankSlotMachineView from "./pages/home/components/bank/BankSlotMachineView";
import BankSpeakEasyView from "./pages/home/components/bank/BankSpeakEasyView";
import BankCityView from "./pages/home/components/bank/BankCityView";
function App() {
    const [isHomeLoading, setIsHomeLoading] = useState(true);
    const [isPrompt, setisPrompt] = useState(false);
    const [isBankLoading, setIsBankLoading] = useState(true);
    const [isElementsHidden, setisElementsHidden] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
    const [topLoadingProgress, setTopLoadingProgress] = useState(0);
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const { mode } = useSelector((state) => state.theme);
    const profile = JSON.parse(localStorage.getItem("profile"));
    // const [pageName, setPageName] = useState(
    //     "bank",
    //     "bankview",
    //     "bridgeview",
    //     "bankMobileNavbar",
    //     "bankcasinoview",
    //     "bankwhitelistview",
    //     "bankslotmachineview",
    //     "normal" || "normal"
    // );
    const [navbarVisible, setNavbarVisible] = useState(false);
    const audioRef = useRef(null);
    const [isMusicPlayed, setIsMusicPlayed] = useState(false);
    const [isUserInteracted, setIsUserInteracted] = useState(false);
    const handleHide = () => {
        setisElementsHidden(!isElementsHidden);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1024);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSubscribe = async () => {
        Navigate("/#footer");
    };
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const platform = urlParams.get("platform");
        const uid = urlParams.get("uid");
        const referral = urlParams.get("referral");
        if (referral) {
            localStorage.setItem("referral", referral);
            localStorage.setItem("refferedBy", referral);
        }
        if (platform && uid) {
            localStorage.setItem(platform, uid);
            window.location.href = "/whitelist";
        }
    }, []);

    useEffect(() => {
        // We are not using dark/light theme
        const savedMode = localStorage.getItem("themeModeMob");
        if (savedMode) {
            dispatch(toggleTheme(savedMode));
        }
    }, [dispatch]);
    useEffect(() => {
        localStorage.setItem("themeModeMob", "light");
    }, [mode]);

    gsap.registerPlugin(
        ScrollTrigger,
        ScrollToPlugin,
        ScrollSmoother,
        SplitText,
        useGSAP
    );

    const playMusic = () => {
        const audio = audioRef.current;
        setIsMusicPlayed(!isMusicPlayed);
        setIsUserInteracted(true);
        if (isMusicPlayed) {
            audio.pause();
        } else {
            audio.play();
        }
    };

    useEffect(() => {
        audioRef.current = new Audio(halls);
        audioRef.current.volume = 0.07;
        audioRef.current.loop = true;
    }, []);
    useEffect(() => {
        // Set a timeout to simulate loading process
        const timer = setTimeout(() => {
            setIsBankLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const startMusicOnInteraction = () => {
            playMusic();
            setIsUserInteracted(true); // Ensuring it only triggers once
            // Remove event listener after interaction
            document.removeEventListener("click", startMusicOnInteraction);
        };

        if (!isUserInteracted) {
            document.addEventListener("click", startMusicOnInteraction);
        }

        // Cleanup function to make sure we clean up if the component unmounts
        return () => {
            document.removeEventListener("click", startMusicOnInteraction);
        };
    }, [isUserInteracted]);

    return (
        <div className={`${mode === "dark" ? "dark-mode" : "light-mode"} `}>
            <ToastContainer />
            <Router>
                {" "}
                <Routes>
                    {/*Loading bar and navbar......................*/}
                    <Route
                        element={
                            <div className="scroll-smooth antialiased flex flex-col min-h-screen relative w-full ">
                                {isHomeLoading === false && (
                                    <div className="w-full absolute md:fixed top-0 z-40 pt-5">
                                        <LoadingBar
                                            color="#C5FCFC"
                                            progress={topLoadingProgress}
                                        ></LoadingBar>
                                        <Navbar
                                            data={data}
                                            isLargeScreen={isLargeScreen}
                                            setIsLargeScreen={setIsLargeScreen}
                                            setNavbarVisible={setNavbarVisible}
                                            handleSubscribe={handleSubscribe}
                                        />
                                    </div>
                                )}

                                <Outlet />

                                <div className="flex-grow"></div>
                            </div>
                        }
                    >
                        {/*Home......................*/}
                        {/* <Route
                            element={
                                <Home
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    isMusicPlayed={isMusicPlayed}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                    setisPrompt={setisPrompt}
                                    isPrompt={isPrompt}
                                    data={data}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    setPageName={setPageName}
                                    handleHide={handleHide}
                                    pageName={pageName}
                                    navbarVisible={navbarVisible}
                                    isBankLoading={isBankLoading}
                                    setIsBankLoading={setIsBankLoading}
                                    setIsHomeLoading={setIsHomeLoading}
                                    isHomeLoading={isHomeLoading}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                />
                            }
                            path="/"
                        />
                    </Route> */}
                        <Route
                            element={
                                <Bank
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    isMusicPlayed={isMusicPlayed}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                    setisPrompt={setisPrompt}
                                    isPrompt={isPrompt}
                                    data={data}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    handleHide={handleHide}
                                    navbarVisible={navbarVisible}
                                    isBankLoading={isBankLoading}
                                    setIsBankLoading={setIsBankLoading}
                                    setIsHomeLoading={setIsHomeLoading}
                                    isHomeLoading={isHomeLoading}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                />
                            }
                            path="/"
                        />

                        <Route
                            element={
                                <BridgeView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/bridge"
                        />
                        <Route
                            element={
                                <BankView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/bank"
                        />
                        <Route
                            element={
                                <BankSpeakEasyView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/speakeasy"
                        />
                        <Route
                            element={
                                <BankCityView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/city"
                        />
                        <Route
                            element={
                                <BankCasinoView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/casino"
                        />
                        <Route
                            element={
                                <BankSlotMachineView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/slot"
                        />
                        <Route
                            element={
                                <BankWhitelistView
                                    handleHide={handleHide}
                                    setisElementsHidden={setisElementsHidden}
                                    isElementsHidden={isElementsHidden}
                                    data={data}
                                    isLargeScreen={isLargeScreen}
                                    setIsLargeScreen={setIsLargeScreen}
                                    handleSubscribe={handleSubscribe}
                                    setIsMusicPlayed={setIsMusicPlayed}
                                    isMusicPlayed={isMusicPlayed}
                                    isUserInteracted={isUserInteracted}
                                    setIsUserInteracted={setIsUserInteracted}
                                    audioRef={audioRef}
                                    playMusic={playMusic}
                                />
                            }
                            path="/whitelist"
                        />
                    </Route>
                    {/*Error 404 not found......................*/}
                    <Route path="*" element={<NotFound />} />
                </Routes>{" "}
            </Router>
        </div>
    );
}

export default App;
